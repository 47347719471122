
import { useRef, useState } from "react";
import {getGoogleMapsApiClient} from "@libs";
export const useGAddress = ({onLocationSelected = () => { }}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [placeDetail, setPlaceDetail] = useState();

  // let's add a timeout ref here, so we can debounce the calls
  const timeoutRef = useRef();

  // session token is a string to group autocomplete service requests with get place detail requests
  // it's for billing purposes. See the docs: https://developers.google.com/maps/documentation/places/web-service/session-tokens 
  const sessionTokenRef = useRef();


  const loadSuggestions = async (inputValue) => {
    clearTimeout(timeoutRef.current);

    // don't load suggestions if not enough characters
    if (!inputValue || inputValue.trim().length <= 3) {
      setSuggestions([]);
      return;
    }

    // debounce the loading of suggestions to reduce usage of the Google API
    timeoutRef.current = setTimeout(async () => {
      const google = await getGoogleMapsApiClient();
      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new google.AutocompleteSessionToken();
      }

      // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
      new google.AutocompleteService().getPlacePredictions(
        {
          input: inputValue,
          sessionToken: sessionTokenRef.current,
        },
        (predictions, status) => {
          // when the status is 'ZERO_RESULTS', we treat it as if there are 0 suggestions, no error
          // you could change this behavior if you require users to pick a suggestion
          if (status === google.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          if (
            status !== google.PlacesServiceStatus.OK ||
            !predictions
          ) {
            // if you allow your users to enter arbitrary locations that aren't autocompleted,
            // then you can fail silently here and track the error (with Sentry for example)
            return;
          }
          setSuggestions(predictions);
        },
      );
    }, 350);
  };

  const handleSuggestionSelected = async (suggestion) => {


    // update the text in the input to the full selected suggestion text
    // onChange(suggestion.description);

    // clear suggestion list
    setSuggestions([]);

    const google = await getGoogleMapsApiClient();

    // Clear the session token, it can only be used in one request
    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    // @see https://developers.google.com/maps/documentation/javascript/places
    new google.PlacesService(
      // this is the node to populate attribution details on
      document.getElementById("googlemaps-attribution-container")).getDetails(
        {
          placeId: suggestion.place_id,
          fields: [
            // you can pick the fields you want for your application
            // @see https://developers.google.com/maps/documentation/javascript/place-data-fields

        
            "name",
            "geometry.location",
            "place_id",
            "business_status",
            "address_components",
            "photos",
            "website",
            "current_opening_hours",
            "formatted_address",
            "formatted_phone_number",
            "reviews"
          ],
          // pass the session token so all autocomplete requests are counted as part of this places request
          sessionToken,
        },
        (place, status) => {
          if (status === google.PlacesServiceStatus.OK) {
            // set the place detail in this state component
            // you can use this info to show the detail in the UI, or maybe a checkmark
            setPlaceDetail(place);

            // notify up the tree that a location is selected
            onLocationSelected(place);
          } else {
            // silently fail here and track it with an error tracker like Sentry
            // or fail loudly if users are required to use a suggestion from the list
          }
        }
      );



  };


  const onChangeAddress = (event) => {
      const newValue = event.target.value;
      // update controlled input value
      // onChange(newValue);
      // clear any previously loaded place details
      setPlaceDetail(undefined);

      // trigger the load of suggestions
      loadSuggestions(newValue);
    }
  

  return { suggestions, placeDetail, onChangeAddress, handleSuggestionSelected }
}
/*
  // the JSX implementation will vary depending on which UI lib you're using
  return (<Box>

    <div
    
      style={{
        color:'white',
        backgroundColor: "#282c34",
       overflow:'auto',
        padding: "5vh 2rem",
        width: "100%",
      }}
    >
     

      <main style={{ maxWidth: "600px", margin: "0 auto" }}>
       
        <p style={{ fontSize: "1rem" ,color:'white'}}>
          Enter at least 4 characters to load suggestions
        </p>


        {suggestions.length > 0 && (
          <div>
            <h2 style={{ fontSize: "1.5rem", textAlign: "left" }}>
              Suggestions:
            </h2>
            <ul style={{ listStyleType: "none", padding: "0" }} role="listbox">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.place_id}
                  style={{
                    fontSize: "1rem",
                    padding: "0.25rem 0.5rem",
                    margin: "0.25rem 0",
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                    cursor: "pointer",
                  }}
                  tabIndex={-1}
                  role="option"
                  aria-selected="false"
                  onClick={() => handleSuggestionSelected(suggestion)}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          </div>
        )}

        {placeDetail && (
          <div>
            <h2 style={{ fontSize: "1.5rem", textAlign: "left" }}>
              Place detail:
            </h2>
            <pre
              style={{
                fontSize: "0.85rem",
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {JSON.stringify(placeDetail, null, 2)}
            </pre>
          </div>
        )}

        <div id="googlemaps-attribution-container"></div>
      </main>
    </div>



  </Box>);
}*/
