import { chakra } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const Logo = (props) => {
  const navigate = useNavigate()
  return (
    <chakra.svg
      cursor={'pointer'}
      onClick={() => navigate('/')}
      color="accent"
      viewBox="0 0 6000 6000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Layer_x0020_1">
        <rect fill="none" stroke="#0364FF" strokeWidth="166.67" strokeMiterlimit="2.61313" x="1934.2" y="3289.2" width="3011.24" height="1384.57" rx="385.73" ry="221.02" />
        <path fill="#0364FF" fillRule="nonzero" d="M3284.04 4158.41c0,12 -1.5,24.67 -4.17,38 -2.83,13.33 -7.17,26.67 -13,39.83 -5.83,13.17 -13.5,25.83 -22.67,37.83 -9.33,12 -20.5,22.67 -33.67,32 -13.17,9.33 -28.5,16.67 -45.67,22.17 -17.33,5.5 -36.83,8.33 -58.67,8.33l-229 0c-12,0 -24.67,-1.33 -38,-4.17 -13.33,-2.67 -26.67,-7 -39.83,-13 -13.17,-5.83 -25.83,-13.33 -37.83,-22.67 -12,-9.17 -22.67,-20.5 -32,-33.67 -9.33,-13.17 -16.67,-28.5 -22.17,-45.83 -5.67,-17.5 -8.33,-37.17 -8.33,-58.83l0 -572.83 127 0 0 572.83c0,15.5 4.83,28 14.5,37.33 9.83,9.17 22,13.83 36.67,13.83l229 0c16,0 28.5,-4.67 37.33,-14.17 9,-9.33 13.5,-21.83 13.5,-37l0 -172c0,-15.83 -4.67,-28.33 -14.17,-37.33 -9.5,-8.83 -21.67,-13.33 -36.67,-13.33l-229 0 0 -127 229 0c12.17,0 24.83,1.33 38.17,4.17 13.33,2.83 26.5,7 39.83,13 13.17,5.83 25.67,13.33 37.83,22.67 12,9.17 22.5,20.5 31.67,33.67 9.17,13.17 16.5,28.33 22,45.67 5.5,17.17 8.33,36.83 8.33,58.5l0 172z" />
        <path id="_1" fill="#0364FF" fillRule="nonzero" d="M3521.37 3700.41l-127 0 0 -114.83 127 0 0 114.83zm0 636.17l-127 0 0 -527.83 127 0 0 527.83z" />
        <path id="_2" fill="#0364FF" fillRule="nonzero" d="M4142.87 3847.74c5.33,12.17 6.67,24.33 4.17,36.67 -2.33,12.33 -8,23.17 -16.83,32.17l-293.33 293 305.17 0 0 127 -458 0c-13.17,0 -24.83,-3.67 -35.5,-11 -10.5,-7.33 -18.5,-16.67 -23.67,-28 -4.83,-12.17 -6.17,-24.33 -3.67,-37 2.5,-12.5 8.33,-23.5 17.83,-32.83l292 -292 -305.17 0 0 -127 459 0c12.67,0 24.33,3.5 34.83,10.33 10.67,6.83 18.33,16.33 23.17,28.67z" />
        <path fill="#00AFEF" fillRule="nonzero" d="M2217.3 3017.85c3.83,0.33 8.33,0.5 13.5,0.67 5,0.17 12.5,0.33 22.67,0.33 58.33,0 111.33,-11.17 159,-33.33 47.67,-22.17 89,-55 123.83,-98.83 9.5,-11.33 19.33,-26.67 30,-46 10.5,-19.17 19.5,-28.83 27,-28.83 5.17,0 9.17,1.5 11.83,4.33 2.83,3 4.17,7 4.17,12.33 0,9 -5.67,23.17 -17,42 -11.5,18.83 -26.17,38 -44,57.17 -38.67,40.67 -82.33,71.67 -131,92.67 -48.67,21 -100.67,31.5 -156,31.5 -55.83,0 -108.17,-10 -156.67,-29.83 -48.33,-19.83 -90.5,-48.67 -126.33,-86.33l0 -514.83c37.83,-39.67 80.17,-69.5 127.17,-89.33 47,-19.83 98.67,-29.67 155,-29.67 49.83,0 96.17,7.67 139,23.17 42.67,15.5 83.83,39.5 123.17,72 18,14.67 31.83,28.17 41.83,40.67 9.83,12.5 14.83,22.5 14.83,30 0,4 -1.5,7.33 -4.67,10 -3,2.83 -6.67,4.17 -11,4.17 -7.17,0 -16.67,-7.17 -28.83,-21.5 -9.67,-11.33 -18.17,-20.33 -25.33,-26.83 -36.83,-33.17 -76.17,-58.17 -118.33,-74.83 -42,-16.5 -86.17,-24.83 -132.83,-24.83 -4.83,0 -10,0 -15.33,0.17 -5.33,0.17 -14,0.83 -25.67,1.83l0 682zm-280 -134c-20.33,-30 -35.5,-62.33 -45.67,-96.83 -10.17,-34.5 -15.33,-71.17 -15.33,-110.33 0,-37.5 5.17,-73.33 15.33,-107.83 10.17,-34.67 25.33,-67.67 45.67,-99.17l0 414.17z" />
        <path id="_1_0" fill="#00AFEF" fillRule="nonzero" d="M2760.46 2935.85l0 -515c37.67,-39 79.67,-68.67 125.83,-88.83 46.33,-20.17 95.67,-30.17 148.33,-30.17 51.17,0 99.33,9.33 144.5,27.83 45.17,18.5 85.17,45.5 120,81 35.5,36.17 62.83,77.17 81.83,122.83 19,45.83 28.5,93.5 28.5,143.33 0,50.5 -9.5,98.5 -28.5,144.33 -19,45.67 -46.33,86.33 -81.83,121.83 -35,35.5 -75.33,62.5 -120.67,81 -45.5,18.5 -93.33,27.83 -143.83,27.83 -51.67,0 -101.17,-10 -148.5,-30 -47.33,-20 -89.17,-48.67 -125.67,-86zm-41 -51.83c-20.33,-30 -35.5,-62.17 -45.67,-96.83 -10.17,-34.5 -15.33,-71.33 -15.33,-110.33 0,-37.5 5.17,-73.5 15.33,-108 10.17,-34.5 25.33,-67.5 45.67,-99.17l0 414.33zm280 133.83c3.67,0.33 7.67,0.5 12.5,0.67 4.67,0.17 11.5,0.33 20.17,0.33 41,0 79.83,-6.5 116.33,-19.33 36.33,-12.83 70.5,-32 102,-57.33 40.33,-33.33 71.5,-72.83 93.33,-118.5 21.83,-45.83 32.67,-94.67 32.67,-146.83 0,-51.5 -11,-100.33 -33,-146.83 -22,-46.33 -53,-85.83 -93,-118.5 -31.83,-25.67 -65.83,-44.83 -101.83,-57.67 -36,-12.67 -74.33,-19 -115,-19 -9.5,0 -16.67,0 -21.5,0.17 -4.83,0.17 -9,0.5 -12.67,0.83l0 682z" />
        <path id="_2_1" fill="#00AFEF" fillRule="nonzero" d="M4274.79 2935.85l0 -515c37.67,-39 79.67,-68.67 125.83,-88.83 46.33,-20.17 95.67,-30.17 148.33,-30.17 51.17,0 99.33,9.33 144.5,27.83 45.17,18.5 85.17,45.5 120,81 35.5,36.17 62.83,77.17 81.83,122.83 19,45.83 28.5,93.5 28.5,143.33 0,50.5 -9.5,98.5 -28.5,144.33 -19,45.67 -46.33,86.33 -81.83,121.83 -35,35.5 -75.33,62.5 -120.67,81 -45.5,18.5 -93.33,27.83 -143.83,27.83 -51.67,0 -101.17,-10 -148.5,-30 -47.33,-20 -89.17,-48.67 -125.67,-86zm-41 -51.83c-20.33,-30 -35.5,-62.17 -45.67,-96.83 -10.17,-34.5 -15.33,-71.33 -15.33,-110.33 0,-37.5 5.17,-73.5 15.33,-108 10.17,-34.5 25.33,-67.5 45.67,-99.17l0 414.33zm280 133.83c3.67,0.33 7.67,0.5 12.5,0.67 4.67,0.17 11.5,0.33 20.17,0.33 41,0 79.83,-6.5 116.33,-19.33 36.33,-12.83 70.5,-32 102,-57.33 40.33,-33.33 71.5,-72.83 93.33,-118.5 21.83,-45.83 32.67,-94.67 32.67,-146.83 0,-51.5 -11,-100.33 -33,-146.83 -22,-46.33 -53,-85.83 -93,-118.5 -31.83,-25.67 -65.83,-44.83 -101.83,-57.67 -36,-12.67 -74.33,-19 -115,-19 -9.5,0 -16.67,0 -21.5,0.17 -4.83,0.17 -9,0.5 -12.67,0.83l0 682z" />
        <path fill="#3ABFEF" d="M3511.29 2805.2c67.81,-218.99 189.7,-399.98 364.89,-551.47 -15.46,-2.69 -31.35,-4.1 -47.56,-4.1l-369.52 0 0 807.35c10.37,-89.12 27.77,-172.91 52.19,-251.78zm448.31 -522.54c-243.65,335.73 -261.39,440.97 -89.34,774.31l289.59 0 -180.51 -296.52c76.33,-49.64 127.06,-135.7 127.06,-233.05 0,-84.48 -38.22,-160.46 -98.22,-211.52 29.65,-88.57 74.02,-172 131.65,-246.59 -66.75,69.35 -127.4,140.57 -180.24,213.37z" />
        <path fill="#00AFEF" d="M4039.48 2076.23c29.93,-19.9 69.51,-17.25 96.49,6.55 26.95,23.8 34.52,62.77 18.46,94.91l212.86 -161.77 -52.42 -6.81 -75.16 23.94c72.7,-58.82 178.94,-166.18 183.06,-259.64 -89.27,7.42 -175.74,73.66 -259.37,198.72 4.97,-23.99 10.2,-55.01 17.95,-90.1l-41.37 17.24 -100.5 176.96z" />
        <path fill="#373435" fillRule="nonzero" d="M3954.67 2594.95c0,-0.65 0,-1.3 0,-1.95l0 1.95zm53.52 -279.06l0 0z" />
        <path fill="#2C96BB" d="M3979.34 2760.46c-16.23,-50.16 -24.67,-105.95 -24.67,-165.51l0 -1.95c0,-86.35 17.59,-180.6 53.52,-277.11 60,51.06 98.22,127.04 98.22,211.52 0,97.34 -50.73,183.41 -127.06,233.05z" />
      </g>


    </chakra.svg>
  )
}
