import { WarningIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Dropzone } from '@components/dropzone/Dropzone'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { ShieldCheck } from 'react-bootstrap-icons'
import { FiClipboard } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useTimer } from 'src/hooks/useTimer'
import { tokenConfig } from 'src/middlewares/tokenMiddleware'
import copyToClipBoard from 'src/utils/copytoClipBoard'

export const UserProfilePage = () => {
  const { user, loading } = useSelector((states) => states.userrr)
  const [isGeneratingToken, setIsGeneratingToken] = useState(false);
  const [isTokenAvailableToClipboard, setIsTokenAvailableToClipboard] = useState(false);
  const [apiToken, setApiToken] = useState('')
  useEffect(() => {
    if (!loading && !!user) {
      setApiToken(`****************************${user?.apiToken}`)
    }
  }, [user, loading])

  const { timer, start, isTimeUp } = useTimer()


  useEffect(() => {
    if (isTimeUp) {
      setApiToken(`****************************${user?.apiToken}`)
      setIsTokenAvailableToClipboard(false)
    }
  }, [isTimeUp]);


  const handleRequestServiceAPItoken = async () => {
    //TODO: call API to request
    try {
      // eslint-disable-next-line no-restricted-globals 
      if (!confirm("Are you sure you  want to unsubscribe?")) {
        return;
      } else {
        setIsGeneratingToken(true)
        const token = await tokenConfig({});
        toast.promise(
          axios.post(`${process.env.REACT_APP_API_URL}/api/business/token`, {}, token),
          {// eslint-disable-next-line no-restricted-globals
            pending: {
              render() {
                setIsTokenAvailableToClipboard(false)
                return "Generating Token..."
              },
              icon: false,
            },
            success: {
              render({ data: res }) {
                const message = res?.data?.message || 'Token generated successfully';
                const transCode = res?.data?.transCode;
                setIsGeneratingToken(false)
                setIsTokenAvailableToClipboard(true)
                start(25)
                setApiToken(res?.data?.token)
                return message;
              },
              // other options
              icon: "🟢",
            },
            error: {
              render({ data: res }) {
                const error = res?.data?.error || res?.data?.message || 'An error occurred while generating token';
                setIsGeneratingToken(false)
                setIsTokenAvailableToClipboard(false)
                // When the promise reject, data will contains the error
                return error;
              }
            }
          }
        )


      }

    } catch (error) {

    }
  }


  return (<Container
    maxW="container.lg"
    py={{
      base: '4',
      md: '8',
    }}
  >
    <Stack spacing="5">
      <Stack
        spacing="4"
        direction={{
          base: 'column',
          sm: 'row',
        }}
        justify="space-between"
      >
        <Box>
          <Text textStyle="lg" fontWeight="medium">
            Your Account Info
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Last Sign-In :  {new Date(user?.metadata?.lastSignInTime).toLocaleString()}
          </Text>
        </Box>
        <Button alignSelf="start">Save</Button>
      </Stack>
      <Divider />
      <Stack spacing="5" divider={<StackDivider />}>
        <FormControl id="name">
          <Stack
            justifyContent={'flex-start'}
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={{
              base: '1.5',
              md: '8',
            }}
            justify="space-between"
          >
            <FormLabel variant="inline">Name</FormLabel>
            <Input
              maxW={{
                md: '2xl',
              }}
              defaultValue={user?.displayName}
            />
          </Stack>
        </FormControl>
        <FormControl id="email">
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={{
              base: '1.5',
              md: '8',
            }}
            justify="space-between"
          >
            <FormLabel variant="inline">Email</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Tooltip label={user?.emailVerified ? 'This email has been verified' : 'This email is not yet verified.'}>
                  <Icon as={user?.emailVerified ? ShieldCheck : WarningIcon} mr="1" color={user.emailVerified ? 'green' : 'orange'} />
                </Tooltip>
              </InputLeftElement>

              <Input
                type="email"
                maxW={{
                  md: '2xl',
                }}
                defaultValue={user?.email}
              />
            </InputGroup>
          </Stack>
        </FormControl>
        <FormControl id="picture">
          <Stack
            justifyContent={'flex-start'}
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={{
              base: '1.5',
              md: '8',
            }}
            justify="space-between"
          >
            <FormLabel variant="inline">Photo</FormLabel>
            <Stack
              spacing={{
                base: '3',
                md: '5',
              }}
              direction={{
                base: 'column',
                sm: 'row',
              }}
              width="full"
              maxW={{
                md: '2xl',
              }}
            >
              <Avatar size="lg" name="Christoph Winston" src="https://tinyurl.com/yhkm2ek8" />
              <Dropzone width="full" />
            </Stack>
          </Stack>
        </FormControl>
        

        <FormControl id='request_new_service_token'>
          <Stack
            justifyContent={'flex-start'}
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={{
              base: '1.5',
              md: '8',
            }}
            justify="space-between"
          >
            <FormLabel variant="inline" >API Token</FormLabel>
            <InputGroup cursor={'pointer'} onClick={() => isTokenAvailableToClipboard ? copyToClipBoard(apiToken) : () => { alert('Token is no longer available to copy') }}>
              <InputLeftElement display={!isTokenAvailableToClipboard || isGeneratingToken || loading ? 'none' : 'flex'} mr={5}>
                <Tooltip  label="Copy to clipboard">
                  <FiClipboard size={'25'} color='brand.500' />
                </Tooltip>

              </InputLeftElement>
              <Input
                isReadOnly={true}
                maxW={{
                  md: '2xl',
                }}
                value={apiToken}
              />
              {isTokenAvailableToClipboard && <InputRightAddon children={<Tooltip label={`${timer} senconds left available to copy`}><Badge>{timer}</Badge></Tooltip>} />}
            </InputGroup>
            <Button onClick={handleRequestServiceAPItoken} isDisabled={isTokenAvailableToClipboard} isLoading={isGeneratingToken} loadingText='Please wait...'>Generate</Button>
          </Stack>
        </FormControl>
        <Flex direction="row-reverse">
          <Button>Save</Button>
        </Flex>
      </Stack>
    </Stack>
  </Container>
  )
}