import {
  Box,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { fetchAPIservices } from '@redux/actions/service-actions/fetchAPIservices'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ServiceCard } from './ServiceCard'
import { tokenConfig } from 'src/middlewares/tokenMiddleware'
import axios from 'axios'
import { toast } from 'react-toastify'




export const APIServicesList = (props) => {
  const dispatch = useDispatch();
  const { apiServices, loading } = useSelector(state => state.apiServicesss)

  const handleFetch = () => {
    if (!loading)
      dispatch(fetchAPIservices());
  }
  useEffect(() => {
    handleFetch();
  }, [])



  const handleSubscribe = async (id) => {

    if (!!id) {
      const token = await tokenConfig({ id: id });
      axios.post(`${process.env.REACT_APP_API_URL}/api/service/subscription`, {}, token)
        .then(res => {
          const message = res?.data?.message;
          const transCode = res?.data?.transCode;
          toast.success(message)
          handleFetch();
        })
        .catch(err => {
          toast.error(err?.response?.data?.error);
          console.log(err?.response?.data?.error);
        });
    }
  }

  const handleUnsubscribe = async (id) => {


    // eslint-disable-next-line no-restricted-globals 
    if (!confirm("Are you sure you  want to unsubscribe?")) {
      return;
    } else {
      if (!!id) {
        const token = await tokenConfig({ id: id });

        axios.delete(`${process.env.REACT_APP_API_URL}/api/service/subscription`, token)
          .then(res => {
            const message = res?.data?.message;
            const transCode = res?.data?.transCode;
            toast.success(message)
            handleFetch();
          })
          .catch(err => {
            toast.error(err?.response?.data?.error);
            console.log(err?.response?.data?.error);
          });
      }
    }

  }

  if (loading) {
    return (
      <Box w={'full'} h={'full'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Spinner />
      </Box>
    )
  }

  return (<Stack spacing="5" p={8}>
    {apiServices.length > 0 ?
      apiServices.map(service => <ServiceCard handleSubscribe={handleSubscribe} handleUnsubscribe={handleUnsubscribe} key={service._id} service={service} />)
      : <Text>No services found</Text>}
  </Stack>)
}