'use client'

import {
    Box,
    Container,
    Stack,
    Text,
    Image,
    Flex,
    VStack,
    Button,
    Heading,
    SimpleGrid,
    StackDivider,
    useColorModeValue,
    List,
    ListItem,
} from '@chakra-ui/react'
import axios from 'axios'
import {  MdKey,  } from 'react-icons/md'
import { toast } from 'react-toastify'
import { tokenConfig } from 'src/middlewares/tokenMiddleware'

export const APIServiceOverview = ({ service }) => {
    const handleSubscribe = async () => {

        if (!!service && service?._id) {
          const token = await tokenConfig({ id: service._id });
    
          axios.post(`${process.env.REACT_APP_API_URL}/api/service/subscription`, {}, token)
            .then(res => {
              const message = res?.data?.message;
              const transCode = res?.data?.transCode;
              toast.success(message)
            })
            .catch(err => {
              toast.error(err?.response?.data?.error);
              console.log(err?.response?.data?.error);
            });
        }
      }
    
      const handleUnsubscribe = async () => {
        // eslint-disable-next-line no-restricted-globals 
        if (!confirm("Are you sure you  want to unsubscribe?")) {
          return;
        } else {
          if (!!service && service?._id) {
            const token = await tokenConfig({ id: service._id });

            axios.delete(`${process.env.REACT_APP_API_URL}/api/service/subscription`, token)
              .then(res => {
                const message = res?.data?.message;
                const transCode = res?.data?.transCode;
                toast.success(message)
              })
              .catch(err => {
                toast.error(err?.response?.data?.error);
                console.log(err?.response?.data?.error);
              });
          }
        }
     
      }
    return (
        <Container position={'relative'}>
            <SimpleGrid
                columns={{ base: 1, lg: 2 }}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 18, md: 24 }}>
                <Flex position={'sticky'}>
                    <Image
                        rounded={'md'}
                        alt={service?.name || 'Service Image'}
                        src={service?.thumbnail}
                        fit={'contain'}
                        align={'center'}
                        w={'100%'}
                        h={{ base: '100%', sm: '400px', lg: '500px' }}
                    />
                </Flex>
                <Stack spacing={{ base: 6, md: 10 }}>
                    <Box as={'header'}>
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                            {service?.name || 'Service Title'}
                        </Heading>
                        <Text
                            color={useColorModeValue('gray.900', 'gray.400')}
                            fontWeight={300}
                            fontSize={'2xl'}>
                            ${service?.price || '0.00'}/month
                        </Text>
                    </Box>

                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={'column'}
                        divider={
                            <StackDivider borderColor={useColorModeValue('gray.200', 'gray.600')} />
                        }>
                        <VStack spacing={{ base: 4, sm: 6 }}>
                            <Text
                                color={useColorModeValue('gray.500', 'gray.400')}
                                fontSize={'2xl'}
                                fontWeight={'300'}>
                                #{service?.category || 'API Service Category'}
                            </Text>
                            <Text fontSize={'lg'}>
                                {service?.description || 'Service Description'}
                            </Text>
                        </VStack>
                        {service?.subscriptions && <Box>
                            <Text
                                fontSize={{ base: '16px', lg: '18px' }}
                                color={'brand.400'}
                                fontWeight={'500'}
                                textTransform={'uppercase'}
                                mb={'4'}>
                                Status
                            </Text>

                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                                <List spacing={2}>
                                    <ListItem>Started</ListItem>
                                    <ListItem>Expire</ListItem>{' '}
                                    <ListItem>Cancelled</ListItem>

                                </List>
                                <List spacing={2}>
                                    <ListItem>{new Date(service?.subscriptions?.started).toLocaleDateString()}</ListItem>
                                    <ListItem>{new Date(service?.subscriptions?.expires).toLocaleDateString()}</ListItem>
                                    <ListItem>{new Date(service?.subscriptions?.cancelled).toLocaleDateString() || '-'}</ListItem>
                                    <ListItem>{service?.subscriptions?.quote}</ListItem>
                                </List>
                            </SimpleGrid>
                        </Box>}
                        <Box>
                            <Text
                                fontSize={{ base: '16px', lg: '18px' }}
                                color={'brand.400'}
                                fontWeight={'500'}
                                textTransform={'uppercase'}
                                mb={'4'}>
                                Details
                            </Text>

                            <List spacing={2}>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        Methods:
                                    </Text>{' '}
                                    POST | GET | PUT | DELETE
                                </ListItem>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        Auth type:
                                    </Text>{'  '}
                                    {service?.authType || 'API Service Auth Type'}
                                </ListItem>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        Rate limit:
                                    </Text>{' '}
                                    {`${service?.rateLimit?.max || '0'} requests per ${service?.rateLimit?.unit}`}
                                </ListItem>
                                <ListItem>
                                    <Text as={'span'} fontWeight={'bold'}>
                                        Endpoints:
                                    </Text>{' '}
                                    {(service?.endpoints && service?.endpoints?.length) || '0'}{' '}
                                    available

                                </ListItem>

                            </List>
                        </Box>
                    </Stack>

                    <Button
                        rounded={'none'}
                        w={'full'}
                        mt={8}
                        size={'lg'}
                        py={'7'}
                        bg={useColorModeValue('gray.900', 'gray.50')}
                        color={useColorModeValue('white', 'gray.900')}
                        textTransform={'uppercase'}
                        _hover={{
                            transform: 'translateY(2px)',
                            boxShadow: 'lg',
                        }}>
                        {service?.isSubscribed ? 'Unsubscribe' : 'Subscribe'}
                    </Button>

                    <Stack direction="row" alignItems="center" justifyContent={'center'}>
                        <MdKey />
                        <Text>{service?._id}</Text>
                    </Stack>
                </Stack>
            </SimpleGrid>
        </Container>
    )
}