


import { StatIcon } from '@components/stats/statWithIcon/StatIcon'
import HomePage from '@pages/HomePage';
import { LoginPage } from '@pages/LoginPage';
import { MarketingServicesPage } from '@pages/MarketingServicesPage';
import { SignupPage } from '@pages/SignupPage';
import { SupportPage } from '@pages/SupportPage';
import { ServicesPage } from '@pages/ServicesPage';
import { AboutPage } from '../pages/AboutPage';
import { EmailVerificationPage } from '@pages/EmailVerificationPage';
import { MessageCenterPage } from '@pages/MessageCenterPage';
import { UserProfilePage } from '@pages/UseProfilePage';
import { APIServicesPage } from '@pages/APIServicesPage';
import { APIServicesDetailsPage } from '@pages/APIServiceDetailsPage';
import { QRcodeGeneratorPage } from '@pages/QRcodeGeneratorPage';
import { BusinessAccountPage } from '@pages/BusinessAccountPage';
import { ShellWithMenu } from '@components/shell/ShellWhitMenu';


export const publicRoutes = [
    { path: '/', element: <HomePage />, public_route: true, breadcrumb: 'Home' },
    { path: '/login', element: <LoginPage />, public_route: true, breadcrumb: 'Login' },
    { path: '/services', element: <ServicesPage />, public_route: true, breadcrumb: 'Services' },
    { path: '/about', element: <AboutPage />, public_route: true, breadcrumb: 'About' },
    { path: '/email_verification/:uid?/:email?/:code?', element: <EmailVerificationPage />, public_route: true, breadcrumb: 'Email Verification' },
    {
        path: '/registration/:bn?/:bd?/address?/:adrs?/:ct?/:st?/:zc?/:cty?',
        element: <SignupPage />, public_route: true, breadcrumb: 'Registration'
    },
    { path: '/support', element: <SupportPage />, public_route: true, breadcrumb: 'Support' },
    { path: '/marketing', element: <MarketingServicesPage />, public_route: true, breadcrumb: 'Marketing' },

];
export const privateRoutes = [
    { path: 'admin/dashboard', element: <ShellWithMenu children={<StatIcon />} />, breadcrumb: 'Dashbord' },
    { path: 'admin/communication/messages', element: <ShellWithMenu children={<MessageCenterPage />} />, breadcrumb: 'Messages' },
    { path: 'admin/user/profile', element: <ShellWithMenu children={<UserProfilePage />} />, breadcrumb: 'User Profile' },
    { path: 'admin/business/account', element: <ShellWithMenu children={<BusinessAccountPage />} />, breadcrumb: 'Business Account' },
    { path: 'admin/qrcode', element: <ShellWithMenu children={<QRcodeGeneratorPage />} />, breadcrumb: 'QR Code' },
    { path: 'admin/api/services', element: <ShellWithMenu children={<APIServicesPage />} />, breadcrumb: 'Services' },
    { path: 'admin/api/services/:service_id?', element: <ShellWithMenu children={<APIServicesDetailsPage />} />, breadcrumb: 'Service' },

]
