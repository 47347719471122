import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Divider,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaFacebook, FaGithub, FaGoogle, FaInstagram, FaLinkedin, FaTiktok, FaTwitter } from 'react-icons/fa'
import { links } from './_data'
import { Logo } from '@components/CoroBizLogo'

export const FooterWithLogoBelow = () => (
  <Box bg="bg.surface">
    <Container as="footer" role="contentinfo" p={5}>
      <SimpleGrid
        columns={{
          base: 2,
          md: 4,
        }}
        gap="8"
        width={{
          base: 'full',
          lg: 'auto',
        }}
      >
        {links.map((group, idx) => (
          <Stack
            key={idx}
            spacing="4"
            minW={{
              lg: '40',
            }}
          >
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              {group.title}
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              {group.links.map((link, idx) => (
                <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href}>
                  {link.label}
                </Button>
              ))}
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>


      <Divider my={8} />
      <Center>
        <Logo h='10.5em' w='8.6em' />
      </Center>
      <Stack spacing="4">
        <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
          Stay up to date
        </Text>
        <Stack
          spacing="4"
          direction={{
            base: 'column',
            sm: 'row',
          }}
          maxW={{
            lg: '360px',
          }}
        >
          <Input placeholder="Enter your email" type="email" required />
          <Button type="submit" flexShrink={0}>
            Subscribe
          </Button>
        </Stack>
      </Stack>
      <Stack
        pb="12"
        pt="8"
        justify="space-between"
        direction={{
          base: 'column',
          md: 'row',
        }}
        align={{
          base: 'start',
          md: 'center',
        }}
      >
        <HStack
          justify={{
            base: 'space-between',
            sm: 'start',
          }}
          width={{
            base: 'full',
            sm: 'auto',
          }}
          spacing="8"
        >

          <ButtonGroup variant="tertiary">
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGoogle />} />
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaFacebook />} />
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaInstagram />} />
            <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTiktok/>} />
          </ButtonGroup>
        </HStack>
        <Text fontSize="sm" color="fg.subtle">
          &copy; {new Date().getFullYear()} CoroBiz, LLC. All rights reserved.
        </Text>
      </Stack>

    </Container>
  </Box>
)

