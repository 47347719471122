import { Box, Center, Container, Heading, SimpleGrid, Stack, } from '@chakra-ui/react'


export const LogoGridWithTiles = ({ title = '', logos = [], columns = { base: 2, md: 3, } }) => (
  <Container
    py={{
      base: '12',
      md: '16',
    }}
  >
    <Stack
      p={12}
      spacing=""
      background={`rgba(20,23,40,0.7)`}
      rounded={'xl'}
      boxShadow={`0 8px 26px -4px rgba(2,6,23,.15),0 8px 9px -5px rgba(2,6,23,.06)`}
      border={'solid 2px'}
      borderColor={`#ffbe63`}
      backdropFilter={`blur(7.3px);`}
      style={{ 'WebkitBackdropFilter': `blur(7.3px)` }}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        minH={'7em'}
      >
        <Heading
          as={'h3'}
          size={'md'}
          fontWeight="medium"
          textAlign="center"
          color={`#ffbe63!important`}
        >
          {title}
        </Heading>
      </Box>
      <SimpleGrid
        gap={{
          base: '10',
          md: '16',
        }}
        columns={columns}
      >
        {Object.entries(logos).map(([name, Logo]) => (
          <Center
          boxShadow={`-10px 10px 0px rgba(33, 33, 33, 0.6), -20px 20px 0px rgba(33, 33, 33, 0.4), -30px 30px 0px rgba(33, 33, 33, 0.3), -40px 40px 0px rgba(33, 33, 33, 0.1);`}
            key={name}
            bg="bg.muted"
            py={{
              base: '4',
              md: '8',
            }}
         
            borderRadius="lg"
          >
            <Logo
              h={{
                base: '8',
                md: '10',
              }}
              maxW="180px"
              fill={"fg.emphasized"}
            />
          </Center>
        ))}
      </SimpleGrid>
    </Stack>
  </Container >
)
