import { FETCH_SERVICES_FAILURE, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS, FETCH_SERVICE_DETAILS_FAILURE, FETCH_SERVICE_DETAILS_REQUEST, FETCH_SERVICE_DETAILS_SUCCESS } from "@redux/actions/service-actions/service_types";
import { } from "../actions/types";

const initialState = {
  apiServices: [],
  service: {},//service selected details 
  loading: false,
  error: null
};

const APIservicesReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH
    case FETCH_SERVICES_REQUEST:
      return {
        ...state,
        apiServices: [],
        error: null,
        loading: true
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        apiServices: action.payload.apiServices,
        loading: false,
        error: null
      };

    case FETCH_SERVICES_FAILURE:
      return {
        ...state,
        apiServices: [],
        loading: false,
        error: action.payload.error
      };


    //FETCH SERVICE DETAILS
    case FETCH_SERVICE_DETAILS_REQUEST:
      return {
        ...state,
        service: {},
        loading: true,
        error: null,
      };


    case FETCH_SERVICE_DETAILS_SUCCESS:
      return {
        ...state,
        service: action.payload.service,
        loading: false,
        error: null
      };

    case FETCH_SERVICE_DETAILS_FAILURE:
      return {
        ...state,
        service: {},
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};




export default APIservicesReducer;
