import { Box, Button, Divider, Flex, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react'

export const PersonalContactForm = (props) => (
  <Box as="form" bg="bg.surface" boxShadow="sm" borderRadius="lg" {...props}>
    <Stack
      spacing="5"
      px={{
        base: '4',
        md: '6',
      }}
      py={{
        base: '5',
        md: '6',
      }}
    >
      <Stack
        spacing="6"
        direction={{
          base: 'column',
          md: 'row',
        }}
      >

        <FormControl id="firstName">
          <FormLabel>First Name</FormLabel>
          <Input defaultValue="Christoph" />
        </FormControl>
        <FormControl id="lastName">
          <FormLabel>Last Name</FormLabel>
          <Input defaultValue="Winston" />
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={'column'}
      >
        <FormControl id="email" width={'md'}>
          <Flex alignItems="center">
            <FormLabel mb="0">  Email Address</FormLabel>
            <Button variant="link" colorScheme="brand" size="sm">Change Preferences</Button>
          </Flex>
          <Input type="email" defaultValue="cwinston@gmail.com" />
        </FormControl>
        <FormControl id="phone" width={'md'}>
          <FormLabel>Phone Number</FormLabel>
          <Input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" defaultValue="555-555-5555" />
        </FormControl>
      </Stack>


      <FormControl id="street" width={'lg'}>
        <FormLabel>Street</FormLabel>
        <Input defaultValue="Am Kreuzberg 3" />
      </FormControl>
      <Stack
        spacing="6"
        direction={{
          base: 'column',
          md: 'row',
        }}
      >
        <FormControl id="city">
          <FormLabel>City</FormLabel>
          <Input defaultValue="Berlin" />
        </FormControl>
        <FormControl id="state">
          <FormLabel>State / Province</FormLabel>
          <Input />
        </FormControl>
        <FormControl id="zip">
          <FormLabel>ZIP/ Postal Code</FormLabel>
          <Input defaultValue="10961" />
        </FormControl>
      </Stack>
    </Stack>
    <Divider />
    <Flex
      direction="row-reverse"
      py="4"
      px={{
        base: '4',
        md: '6',
      }}
    >
      <Button type="submit">Save</Button>
    </Flex>
  </Box>
)