
// 1. Import the default chakra ui PRo Theme
import { theme } from '@chakra-ui/pro-theme'
import { corobizTheme } from '@themes/corobizTheme' 
// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"


// 2. Call `extendTheme` and pass your custom values

const proTheme = extendTheme(theme)

const extenstion = {
  components: {
    ...proTheme.components,
    Heading: {
      ...proTheme.components.Heading,
      ...corobizTheme.components.Heading
    }
  },

  fonts: {
    ...proTheme.fonts,
    ...corobizTheme.fonts
  },
  colors: {
    ...proTheme.colors, brand: corobizTheme.brand.colors.secondary
  },
}
const myTheme = extendTheme(extenstion, proTheme)


export default myTheme;

