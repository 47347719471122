import { Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import { StepCircle } from './StepCircle'

export const Step = (props) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title, description, ...stackProps } =
    props
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  const orientation = useBreakpointValue({
    base: 'horizontal',
    md: 'vertical',
  })
  return (
    <Stack
      spacing="4"
      direction={{
        base: 'column',
        md: 'row',
      }}
      flex="1"
      {...stackProps}
    >
      <Stack
        spacing="0"
        align="center"
        direction={{
          base: 'row',
          md: 'column',
        }}
      >
        <Divider
          display={isMobile ? 'none' : 'initial'}
          orientation={orientation}
          borderWidth="1px"
          borderColor={isFirstStep ? 'transparent' : (isCompleted || isActive) ? 'accent' : 'inherit'}
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth="1px"
          borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
        />
      </Stack>
      <Stack
        m={5}
        spacing="0"
        pb={isMobile && !isLastStep ? '8' : '0'}
        align={{
          base: 'center',
          md: 'start',
        }}
      >
       <Text color="fg.emphasized" display={isMobile?isActive?'block':'none':'block'} fontWeight={isMobile?"xs":"medium"}>
          {title}
        </Text>
        {!isMobile && <Text color="fg.muted">{description}</Text>}
      </Stack>
    </Stack>
  )
}
