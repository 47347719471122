import {  LOGIN_FORM_GOOGLE_BUTTON, LOGIN_FORM_ISSUES_OPTION, LOGIN_FORM_ISSUES_QUESTION, LOGIN_FORM_LABEL_EMAIL, LOGIN_FORM_LABEL_PASSWORD, LOGIN_FORM_NOT_REGISTERED_OPTION, LOGIN_FORM_NOT_REGISTERED_QUESTION, LOGIN_FORM_PLACEHOLDER_EMAIL, LOGIN_FORM_PLACEHOLDER_PASSWORD, LOGIN_FORM_REMEBER_THIS_DEVICE, LOGIN_FORM_SUBMIT_BUTTON, LOGIN_FORM_TITLE, LOGIN_HELMET_TITLE,} from "../types";
const es_login_page={//Login  and Registration Pages
  helmet:{
    [LOGIN_HELMET_TITLE]:"CoroBiz | Iniciar sesión",
  },
    form: {
        [LOGIN_FORM_TITLE]: 'Inicia sesión en tu cuenta',
        [LOGIN_FORM_NOT_REGISTERED_QUESTION]: "¿No tienes una cuenta?",
        [LOGIN_FORM_NOT_REGISTERED_OPTION]: '¡Regístrate aquí!',
        [LOGIN_FORM_ISSUES_QUESTION]: "¿Tiene algún problema con el proceso de autenticación?",
        [LOGIN_FORM_ISSUES_OPTION]: 'Contáctenos',
        [LOGIN_FORM_REMEBER_THIS_DEVICE]: 'Recordar este  dispositivo',
        [LOGIN_FORM_LABEL_EMAIL]: 'Email',
        [LOGIN_FORM_PLACEHOLDER_EMAIL]: 'Introduzca su  email...',
        [LOGIN_FORM_LABEL_PASSWORD]: 'Contraseña',
        [LOGIN_FORM_PLACEHOLDER_PASSWORD]: 'Introduzca  su contraseña...',
        [LOGIN_FORM_SUBMIT_BUTTON]: 'Enviar',
        [LOGIN_FORM_GOOGLE_BUTTON]:'Iniciar Sesión con Google',
    }
}
export default es_login_page;