import { Route, Routes, } from 'react-router-dom';

/*import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './redux/actions/user-actions/loadUser';
import { useEffect } from 'react';*/


import { Box, Spinner, VStack } from '@chakra-ui/react';
import { Logo } from '@components/CoroBizLogo';
import { v4 as uuid } from 'uuid';
import { privateRoutes, publicRoutes } from './Routes';
import { ShellWithMenu } from '../components/shell/ShellWhitMenu';
import Page404 from '../pages/404';
import { LoginPage } from '../pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadUser } from '../redux/actions/user-actions/loadUser';
export const routes = [
  ...publicRoutes,
  ...privateRoutes,

];

export const Router = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    const cancelSubscription = dispatch(loadUser());
    return () => cancelSubscription;
  }, []);

  const { user = null, loading, } = useSelector(state => state.userrr);

  // this method is to control 404 not found page
  const generateRoute = (path, compt, public_route, exact = false) => {
    if (!!user && !public_route) {
      return <Route key={uuid()} path={path} element={compt} exact={exact} />;
    } else {
      return <Route key={uuid()} path={path} element={<LoginPage />} exact />;
    }
  };

  if (loading) {
    return (<Box w={'full'} h={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <VStack>
        <Spinner />
        <Logo height={'35em'} width={'35em'}/>
      </VStack>
    </Box>)
  }
  return (
    <Routes>
      {routes.map(({ path, element, public_route = false, exact = false }) => {
        if (public_route)
          return (<Route key={uuid()} path={path} element={element} exact={exact} />)
        return generateRoute(path, element, exact)
      })}


      <Route path="*" element={<Page404 />} />

    </Routes>
  )

}

