
import { ACCESSIBLE_DESCRIPTION, ACCESSIBLE_HEADER, ADMIN_DASHBOARD_DESCRIPTION, ADMIN_DASHBOARD_HEADER, BUSINESS_PROMOTION_DESCRIPTION, BUSINESS_PROMOTION_HEADER, DASHBOARD_BUTTON, FEATURES_SECTION_HEADER,  LOGIN_HELMET_TITLE,  ONLINE_BOOKING_DESCRIPTION, ONLINE_BOOKING_HEADER, PAYMENT_DESCRIPTION, PAYMENT_HEADER, SMS_EMAIL_NOTIFICATIONS_DESCRIPTION, SMS_EMAIL_NOTIFICATIONS_HEADER,  WEBSITE_TEMPLATES_DESCRIPTION, WEBSITE_TEMPLATES_HEADER, WELCOME_BUSINESS_DESCRIPTION, WELCOME_BUSINESS_HEADER } from "../types";


const en_home_page = {
    helmet:{
        [LOGIN_HELMET_TITLE]:"CoroBiz",
      },
        section: {//Sections  within the Home page
            main: {//Main  Section of the Home Page
                [WELCOME_BUSINESS_HEADER]: `It's more than just a website, it's a reflection of your future`,
                [WELCOME_BUSINESS_DESCRIPTION]: `We offer a range of services that are designed to give your company the boost it needs to succeed. Take your business to the next level.`,
                [DASHBOARD_BUTTON]: 'Dasboard',
            

            },
            features: {
                [FEATURES_SECTION_HEADER]: "Features",
                [ONLINE_BOOKING_HEADER]: 'Online Booking',
                [ONLINE_BOOKING_DESCRIPTION]: 'Your own booking website or integration with your existing website. We also, work with Facebook, Google, Instagram, Tik Tok, WhatsApp.',
                [BUSINESS_PROMOTION_HEADER]: 'Business Promotion',
                [BUSINESS_PROMOTION_DESCRIPTION]: 'Effortlessly create your next promotion with CoroBiz and see your business blowing the market.',
                [ADMIN_DASHBOARD_HEADER]: 'Admin Dashboard',
                [ADMIN_DASHBOARD_DESCRIPTION]: "Take control of your business with ease, whether you're on the go or in the office, with our personalized admin dashboard and efficient admin app.",
                [SMS_EMAIL_NOTIFICATIONS_HEADER]: "SMS & Email Notifications",
                [SMS_EMAIL_NOTIFICATIONS_DESCRIPTION]: "Stay on top of your business with real-time notifications for all activity, from new bookings to messages from customers.",
                [PAYMENT_HEADER]: "Payment Gateway Integration",
                [PAYMENT_DESCRIPTION]: "We provide a range of secure payment gateways, including trusted platforms such as PayPal, Apple Pay, and Google Pay. To explore the full list of our supported payment options, simply click the button below.",
                [WEBSITE_TEMPLATES_HEADER]: "Our Website Templates",
                [WEBSITE_TEMPLATES_DESCRIPTION]: "Our expertly crafted selection of professional and intuitive templates will empower you to effortlessly achieve your goals.",
                [ACCESSIBLE_HEADER]: "Accessible for all",
                [ACCESSIBLE_DESCRIPTION]: "We believe in creating a world where every individual has equal access to information, and our websites are designed with this vision in mind. We strive to make our digital platforms accessible to everyone, regardless of their abilities.",
                //[MOBILE_RESPONSIVE_DEVICE_TEXT]: "This website is fully responsive and works on any device with an internet browser."
                /* [SEO_OPTIMIZATION_HEADER] : "SEO Optimization",
                 [SOCIAL_NETWORK_MARKETING_HEADER]    : "Social Network Marketing"*/
            }
        }
    


  


}


export default en_home_page;