
import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Heading,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react'
import { CustomSelect } from '@components/customSelect/CustomSelect';
import { Option } from '@components/customSelect/Option';
import { APIServiceOverview } from '@components/service/APIServiceOverview';
import { fetchAPIserviceDetails } from '@redux/actions/service-actions/fetchAPIserviceDetails';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import SEO from 'src/searchEnigineO/SEO'


export const APIServicesDetailsPage = () => {
    const { service_id } = useParams();
    const { service, loading } = useSelector(state => state.apiServicesss)
    const dispatch = useDispatch();
    const fetchServiceDetails = () => {
        if (!loading)
            dispatch(fetchAPIserviceDetails(service_id))
                .then(res => {
                    const message = res?.data?.message;
                    const transCode = res?.data?.transCode;
                    toast.success(message)
                }).catch(err => {
                    toast.error(err?.response?.data?.error);
                    console.log(err?.response?.data?.error);
                });
    }


    useEffect(() => {
        fetchServiceDetails();
    }, [service_id])


    const navigate = useNavigate();
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })


    if (!service_id) {
        navigate('/admin/api/services');
        return null;
    }
    if (loading) {
        return <Stack>
            <Text>Loading...</Text>
        </Stack>
    }
    return (
        <Box
            as="section"
            bg="bg.surface"
            pt={{
                base: '4',
                md: '8',
            }}
            pb={{
                base: '12',
                md: '24',
            }}
        >
            <SEO
                title={`${service?.name || 'API Service'} | CoroBiz`}
                description={service?.description || 'API Service'}
                name={'CoroBiz'}
            />
            <Container>
                <Stack spacing="5">
                    <Stack
                        spacing="4"
                        direction={{
                            base: 'column',
                            sm: 'row',
                        }}
                        justify="space-between"
                    >
                        <VStack justifyContent={'flex-start'} fontWeight={'bold'}>
                            <Heading fontSize="3xl" fontWeight="700" width={'full'}>
                                {service?.name || 'API Service'}
                            </Heading>
                            <Text color="fg.muted" w={'full'}>
                                #{service?.category || 'API Service Category'}
                            </Text>
                            <Divider />

                        </VStack>
                        <Stack direction={isMobile ? 'column' : 'row'}>
                            <Text color={'brand.500'} fontSize={'smaller'}>
                                ID:{' '}{service_id}
                            </Text>

                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <Badge rounded={'lg'} bgColor={service?.isSubscribed ? '#37FF8B' : '#51D6FF'} color={'black'}>{service?.isSubscribed ? 'Subscribed' : 'Not Subscribed'}</Badge>
                            <Badge display={!!service?.subscriptions?.status ? 'block' : 'none'} rounded={'lg'} bgColor={service?.subscriptions?.status === 'active' ? '#37FF8B' : '#FFD451'}  >{service?.subscriptions?.status?.toUpperCase()}</Badge>
                        </Stack>
                    </Stack>
                    {isMobile ? (
                        <CustomSelect value="Overview">
                            <Option value="Overview" />
                            <Option value="Admins" />
                            <Option value="Moderators" />
                            <Option value="Users" />
                            <Option value="Invitations" />
                        </CustomSelect>
                    ) : (
                        <Tabs variant="enclosed">
                            <TabList>
                                <Tab>Overview</Tab>
                                <Tab>Admins</Tab>
                                <Tab>Moderators</Tab>
                                <Tab>Users</Tab>
                                <Tab>Invitations</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <APIServiceOverview service={service} />
                                </TabPanel>
                                <TabPanel>
                                    <p>two!</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>three!</p>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    )}
                </Stack>

            </Container>
        </Box >
    )
}



