
import { tokenConfig } from "@middlewares/tokenMiddleware";
import axios from "axios";
import { FETCH_BUSINESS_ACCOUNTS_FAILURE, FETCH_BUSINESS_ACCOUNTS_REQUEST, FETCH_BUSINESS_ACCOUNTS_SUCCESS } from "./businessTypes";


export const fetchBusinessAccounts = (paginate) => {
    let params = {
        page: paginate?.page,
        limit: paginate?.limit || 20,
        sortBy: paginate?.sortBy || { id: 1 },
    };

    return async (dispatch) => {
        const token = await tokenConfig(params);
        dispatch(fetchBusinessAccountsRequest());
        axios.get(`${process.env.REACT_APP_API_URL}/api/business/owner`, token)
            .then(res => {
                dispatch(fetchBusinessAccountsSuccess({ list: res.data.list, paginate: res.data.paginate }));
            })
            .catch(err => {
                dispatch(fetchBusinessAccountsFailure(err.error));
            });
    };
};

const fetchBusinessAccountsRequest = () => {
    return {
        type: FETCH_BUSINESS_ACCOUNTS_REQUEST
    };
};

const fetchBusinessAccountsSuccess = ({ list, paginate }) => {
    return {
        type: FETCH_BUSINESS_ACCOUNTS_SUCCESS,
        payload: {
            business: list || [],
            paginate: paginate || {}
        }
    };
};

const fetchBusinessAccountsFailure = error => {
    return {
        type: FETCH_BUSINESS_ACCOUNTS_FAILURE,
        payload: {
            error
        }
    };
};
