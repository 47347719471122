import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { APIServicesList } from '@components/service/APIServicesList'

import { FiSearch } from 'react-icons/fi'
import SEO from 'src/searchEnigineO/SEO'


export const APIServicesPage = () => {

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  return (
    <Container
      py={{
        base: '4',
        md: '8',
      }}
      px={{
        base: '0',
        md: 8,
      }}
    >
      <SEO
        title="API Services | CoroBiz"
        description="API Services"
        name={'CoroBiz'}
      />
      <Box
        bg="bg.surface"
        boxShadow={{
          base: 'none',
          md: 'sm',
        }}
        borderRadius={{
          base: 'none',
          md: 'lg',
        }}
      >
        <Stack spacing="5">
          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pt="5"
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                Services
              </Text>
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
            </Stack>
          </Box>

          <APIServicesList />

          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pb="5"
          >
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="fg.muted" textStyle="sm">
                  Showing 1 to 5 of 42 results
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{
                  base: 'full',
                  md: 'auto',
                }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Container>
  )
}