
import {
  Box,
  VStack,
  Button,
  Flex,
  Divider,
  Grid,
  GridItem,
  Container,
  useColorModeValue,
  HStack,
  Text,
  ButtonGroup,
  Badge,
  Link,
  Heading,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';




export const ServiceCard = ({ handleSubscribe = () => { }, handleUnsubscribe = () => { }, service }) => {
  const status = service?.subscriptions?.status;
  const price = String(service?.price).split('.')
  const navigate = useNavigate();

  const handleSelect = () => {
    navigate(`/admin/api/services/${service?._id}`)
  }
  return (
    <Box as={Container} maxW="7xl" mt={5} p={6}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'2xl'}
      rounded={'md'}
    >
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        cursor={'pointer'}
        onClick={handleSelect}
        gap={4}>
        <GridItem colSpan={1}>
          <VStack alignItems="flex-start" spacing={2}>
            <HStack justifyContent={'center'}>
              <Badge rounded={'lg'} bgColor={service?.isSubscribed ? '#37FF8B' : '#51D6FF'} color={'black'}>{service?.isSubscribed ? 'Subscribed' : 'Not Subscribed'}</Badge>
              <Badge display={!!status ? 'block' : 'none'} rounded={'lg'} bgColor={status === 'active' ? '#37FF8B' : '#FFD451'}  >{status?.toUpperCase()}</Badge>
            </HStack>
            <VStack justify={'start'} spacing={0}>
              <Heading fontSize="3xl" fontWeight="700" width={'full'}>
                {service?.name || 'Service Title'}
              </Heading>
              <Text
                w={'full'}
                color="accent"
                fontSize={{
                  base: 'md',
                  md: 'lg',
                }}
              >
                #{service?.category || 'Service Category'}
              </Text>
            </VStack>
          </VStack>
        </GridItem>
        <GridItem >
          <VStack spacing={0} justifyContent={'start'}>
            <Flex>
              <Text noOfLines={3}>
                {service?.shortDescription || service?.description || 'Service Detailed Description'}
              </Text>
            </Flex>
            <Flex w={'100%'} justifyContent={'flex-end'}>
              <Link href={`/admin/api/services/${service?._id}`} onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                handleSelect();
              }} color={'brand.500'}>
                Read More
              </Link>
            </Flex>
          </VStack>
        </GridItem>
      </Grid>

    </Box>
  )
}