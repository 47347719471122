'use client'

import { Center, Divider, Heading, Link, Spinner } from '@chakra-ui/react'
import {
    Button,
    FormControl,
    Flex,
    Stack,
    useColorModeValue,
    HStack,
} from '@chakra-ui/react'
import { PinInput, PinInputField } from '@chakra-ui/react'
import { Logo } from '@components/CoroBizLogo'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { tokenConfig } from 'src/middlewares/tokenMiddleware'

export const EmailVerificationPage = ({ uid }) => {
    // Get the code and uid parameter from the URL.
    const { code: param_code, uid: param_uid, email } = useParams()
    const [isReady, setIsReady] = useState(false)
    const [code, setCode] = useState('')
    const [user_id, setUserId] = useState(uid)

    useEffect(() => {
        if (param_code || param_uid) {
            setCode(param_code)
            setUserId(param_uid)
        }
        setIsReady(true)
    }, [param_code, param_uid])

    const verifyEmail = async () => {
        try {
            const params = { user: user_id, verification_code: code }
            const verification_url = process.env.REACT_APP_API_URL + "/api/user/email/verify";
            const token = await tokenConfig()
            toast.promise(
                axios.put(verification_url, params, token),
                {
                    pending: 'Verifying your account, please wait...',
                    success: 'Your account has been verified successfully👌',
                    error: {
                        render({ data }) {
                            // When the promise reject, data will contains the error
                            return data?.response?.data?.message || data?.response?.data || data?.message || "An unexpected error occurred"
                        }
                    }
                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Flex
            minH={'70vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
                spacing={4}
                w={'full'}
                maxW={'sm'}
                bg={useColorModeValue('white', 'gray.700')}
                rounded={'xl'}
                boxShadow={'lg'}
                p={6}
                my={10}>
                <Center>
                    <Stack justify={'center'} align={'center'}>
                        <Logo width='8em' heigth='auto' />
                        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                            Verify your Email
                        </Heading>
                    </Stack>
                </Center>
                <Center
                    fontSize={{ base: 'sm', sm: 'md' }}
                    color={useColorModeValue('gray.800', 'gray.400')}>
                    We have sent code to your email
                </Center>
                <Center
                    fontSize={{ base: 'xs', sm: 'sm' }}
                    fontWeight="bold"
                    color={'fg.muted'}>
                    {email}
                </Center>
                <FormControl>
                    <Center>
                        {isReady ? <HStack>
                            <PinInput onChange={(value) => setCode(value)} value={code}>
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack> : <Spinner />}
                    </Center>
                </FormControl>
                <Stack spacing={6}>
                    <Button
                        bg={'blue.400'}
                        color={'white'}
                        onClick={verifyEmail}
                        _hover={{
                            bg: 'blue.500',
                        }}>
                        Verify
                    </Button>
                </Stack>
                <Link margin={'auto'} target='_blank' justifySelf={'flex-end'} href={`https://mail.google.com/#search/support%40corobiz.com++Account+Verification`}>Help me to find my email</Link>
                <Divider />
                <Center>
                    <p style={{ color:'#dddd',fontSize: '10px' }}>{user_id}</p>
                </Center>
            </Stack>

        </Flex>
    )

}