import { Box, Container } from '@chakra-ui/react';
import { PersonalContactForm } from '@components/forms/PersonalContactForm';
import React, { useState } from 'react';

export const QRcodeGeneratorPage = () => {
    const [type, setType] = useState('');


    return (
        <Container>
            <Box maxW={'7xl'}>
                <PersonalContactForm />
            </Box>
        </Container>
    );
}

