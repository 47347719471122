import React from 'react'
import { Button, Text } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/actions/user-actions/logoutAction';



export const ButtonLogout = ({ size = "md", children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <Button className='rounded ' size={size} variant="outline-red" onClick={() => {
            dispatch(logout())
        }}
        justifyContent={'start'}
        alignItems={'center'}
        aria-label='Log Out'
        >
            <ArrowBackIcon color="red" size={25} />
            <Text m={0}  ml={5}>Log Out</Text>{children}
        </Button>

    )
}



