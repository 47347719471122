import { getFirebaseErrorMessage } from "../../../firebase/getFirebaseErrorMessage";
import { LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, USER_LOADING } from "../types";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export const login = (email, password) => dispatch => {
  const auth = getAuth();
  dispatch({ type: USER_LOADING });
  // Sign in with email and password.
  return new Promise((resolve, reject) => signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      dispatch(loginUserSuccess(userCredential.user, LOGIN_USER_SUCCESS, userCredential.user.accessToken));
      resolve('Login Successfull')
    })
    .catch((error) => {
      //Get the error message from the custom error treatment function
      const errorDecode = getFirebaseErrorMessage(error.code)
      dispatch(loginUserFailure(errorDecode));
      reject({message:errorDecode})
    }))

};

const loginUserSuccess = (user, message, token) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      user,
      message,
      token
    }
  };
};

const loginUserFailure = error => {
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      error
    }
  };
};
