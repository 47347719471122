import { Box, Button, Container, Link, Text } from "@chakra-ui/react"
import { CheckboxCard, CheckboxCardGroup } from "../checkbox/CheckboxCardGroup"


export const AgreementForm = ({ isLoading = false, onSubmit = () => { } }) => {

    return (<>
        <Box
            as="section"
            bg="bg.surface"
            py={{
                base: '4',
                md: '8',
            }}
        >
            <Container maxW="lg">
                <CheckboxCardGroup spacing="3">
                    <CheckboxCard value={'terms_and_conditions'}>
                        <Text color="fg.emphasized" fontWeight="medium" fontSize="md">
                            I AGREE WITH THE TERMS AND CONDITIONS
                        </Text>
                        <Text color="fg.muted" textStyle="sm" mt={10}>
                            <Link href='#'>terms  and conditions</Link>
                        </Text>
                    </CheckboxCard>
                    <CheckboxCard value={'privacy_policy'}>
                        <Text color="fg.emphasized" fontWeight="medium" fontSize="md">
                            I AGREE WITH THE PRIVACY POLICY
                        </Text>
                        <Text color="fg.muted" textStyle="sm" mt={10}>
                            <Link href='#'>privacy  policy</Link>
                        </Text>
                    </CheckboxCard>
                </CheckboxCardGroup>
                <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} padding={8}>
                    <Button
                        width={'full'}
                        onClick={onSubmit}
                        colorScheme="green"
                        isLoading={isLoading}
                        loadingText={'Registraring'}
                        type='submit'
                        fontSize={'large'}
                        fontWeight={'bold'}>
                        Submit
                        <span style={{ fontSize: '25px', marginLeft: '8px' }}>&#128640;</span>
                    </Button>
                </Box>
            </Container>
        </Box>
    </>)
}