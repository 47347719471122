
import{LOGIN_FORM_TITLE,LOGIN_FORM_ISSUES_OPTION,LOGIN_FORM_ISSUES_QUESTION,LOGIN_FORM_LABEL_EMAIL,LOGIN_FORM_LABEL_PASSWORD,LOGIN_FORM_NOT_REGISTERED_OPTION,LOGIN_FORM_NOT_REGISTERED_QUESTION,LOGIN_FORM_PLACEHOLDER_EMAIL,LOGIN_FORM_PLACEHOLDER_PASSWORD,LOGIN_FORM_REMEBER_THIS_DEVICE, LOGIN_FORM_SUBMIT_BUTTON, LOGIN_FORM_GOOGLE_BUTTON, LOGIN_HELMET_TITLE} from'../types'


const en_login_page = {//Login  and Registration Pages
    helmet:{
        [LOGIN_HELMET_TITLE]:"CoroBiz | Login",
      },
    form: {

        [LOGIN_FORM_TITLE]: 'Log in to your account',
        [LOGIN_FORM_NOT_REGISTERED_QUESTION]: "Don't have an account?",
        [LOGIN_FORM_NOT_REGISTERED_OPTION]: 'Register here!',
        [LOGIN_FORM_ISSUES_QUESTION]: "Are you experiencing any problems with the authentication process?",
        [LOGIN_FORM_ISSUES_OPTION]: 'Contact us!',
        [LOGIN_FORM_REMEBER_THIS_DEVICE]: 'Remember this Device',
        [LOGIN_FORM_LABEL_EMAIL]: 'Email',
        [LOGIN_FORM_PLACEHOLDER_EMAIL]: 'Enter your email address...',
        [LOGIN_FORM_LABEL_PASSWORD]: 'Password',
        [LOGIN_FORM_PLACEHOLDER_PASSWORD]: 'Enter your password...',
        [LOGIN_FORM_SUBMIT_BUTTON]: 'Login',
        [LOGIN_FORM_GOOGLE_BUTTON]: 'Continue with Google',
    },


}


export default en_login_page;