import { Avatar, Badge, Box, Button, Center, Divider, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Heading, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, Stack, StackDivider, Text, Textarea, VStack, useDisclosure, } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { addressSchema } from './ResgisterFormSchema'
import { useParams } from 'react-router-dom'
import { useGAddress } from '@hooks'
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { Suspense, useState } from 'react'
import { ModalForFilter } from '../modals/ModalForFilter'
import countries from '../../bd/countriesAndRegions'

export const BusinessAddressForm = ({
    defaultValues,
    onSubmit = () => { },
    ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()//use for <ModalForFilter/> component
    const { adrs: addressParam, ct: cityParam, st: stateParam, zc: zipcodeParam, cty: countryParam } = useParams()
    const [businessInfo, setBusinessInfo] = useState({})
    const formik = useFormik({
        validationSchema: addressSchema,
        onSubmit: (values) => onSubmit({ address: values, businessInfo: businessInfo }),
        initialValues: {
            address: addressParam || defaultValues.address || '',
            intersection: defaultValues.intersection || '',
            city: cityParam || defaultValues.city || '',
            state: stateParam || defaultValues.city || '',
            zipcode: zipcodeParam || defaultValues.zipcode || '',
            country: countryParam || defaultValues.country || '',
            note: countryParam || defaultValues.note || '',

        }

    });
    const selectAutoCompletePlace = (place) => {
        const address = {}

        console.log(place)

        if (!!place && typeof place === 'object') {
            address["formatted_address"] = place?.["formatted_address"]

            place?.["address_components"]?.forEach(element => {
                address[element?.types[0]] = element["long_name"]

            });
            formik.setFieldValue('address', place?.["formatted_address"]?.split(',')?.[0])
            formik.setFieldValue('intersection', address["intersection"] || '')
            formik.setFieldValue('city', address["locality"] || '')
            formik.setFieldValue('state', address["administrative_area_level_1"] || '')
            formik.setFieldValue('country', address["country"] || '')
            formik.setFieldValue('zipcode', address["postal_code"] || '')

        }
    }

    const { suggestions, onChangeAddress, handleSuggestionSelected = () => { } } = useGAddress({
        onLocationSelected: selectAutoCompletePlace,
        country: countryParam
    });




    const handleSelectCountry = (value) => {
        formik.setFieldValue("country", value?.native)
        onClose();
    }


    return (<Box mx={3} {...props}>
        <Center>
            <Heading as={'h4'} size={'xs'}>Business Address</Heading>
        </Center>
        <Divider my={5} />
        <form onSubmit={formik.handleSubmit}>
            <Stack
                spacing="5"
                px={{
                    base: '4',
                    md: '6',
                }}
                py={{
                    base: '5',
                    md: '6',
                }}
            >
                <FormControl id="address" isRequired isInvalid={formik.touched.address && !!formik.errors.address}>
                    <FormLabel>Address</FormLabel>
                    <Input id='address' name='address' value={formik.values.address} autoComplete="off" onChange={(evt) => { formik.handleChange(evt); onChangeAddress(evt) }} placeholder="Enter your business's address" />
                    <FormErrorMessage>{formik.errors.address}</FormErrorMessage>
                </FormControl>

                <Box position={'relative'}>
                    {!!formik.values.intersection && <>
                        <FormControl id="intersection" isInvalid={formik.touched.intersection && !!formik.errors.intersection}>
                            <FormLabel>Intersection</FormLabel>
                            <Input id='intersection' name='intersection' value={formik.values.intersection} autoComplete="off" onChange={formik.handleChange} />
                            <FormErrorMessage>{formik.errors.intersection}</FormErrorMessage>
                        </FormControl>

                        <FormControl id="note" isInvalid={formik.touched.note && !!formik.errors.note}>
                            <FormLabel>Note</FormLabel>
                            <Textarea
                                id='note' name='note'
                                value={formik.values.note}
                                autoComplete="off"
                                onChange={formik.handleChange}
                                placeholder='Is there anything else that you want to share?'
                                size='sm'
                            />

                            <FormErrorMessage>{formik.errors.note}</FormErrorMessage>
                        </FormControl>

                    </>}
                    {suggestions.length > 0 && (

                        <Box w={'100%'} position={'absolute'} top={0} left={0} zIndex={100} py={{ base: '2', md: '4' }}>
                            <Center maxW="sm" mx="auto">
                                <Box bg={'bg.surface'} py="4" boxShadow={'xl'} rounded={'lg'}>
                                    <Stack divider={<StackDivider />} spacing="4">
                                        {suggestions.map((suggestion) => (
                                            <Stack cursor={'pointer'} key={suggestion?.place_id} fontSize="sm" px="4" spacing="0.5" onClick={() => handleSuggestionSelected(suggestion)}>

                                                <Text
                                                    color="fg.muted"
                                                    sx={{
                                                        '-webkit-box-orient': 'vertical',
                                                        '-webkit-line-clamp': '2',
                                                        overflow: 'hidden',
                                                        display: '-webkit-box',
                                                    }}
                                                >
                                                    {suggestion?.description}
                                                </Text>
                                            </Stack>
                                        ))}
                                    </Stack>

                                </Box>
                            </Center>
                        </Box>
                    )}
                    <div id="googlemaps-attribution-container"></div>
                </Box>
                <Stack
                    spacing="6"
                    direction={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <FormControl id="city" isRequired isInvalid={formik.touched.city && !!formik.errors.city}>
                        <FormLabel>City</FormLabel>
                        <Input id='city' name='city' value={formik.values.city} onChange={formik.handleChange} placeholder="Miami" />
                        <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                    </FormControl>
                    <FormControl id="state" isRequired>
                        <FormLabel>State / Province</FormLabel>
                        <Input id='state' name='state' value={formik.values.state} onChange={formik.handleChange} />
                        <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                    </FormControl>


                </Stack>
                <Stack spacing="6"
                    direction={{
                        base: 'column',
                        md: 'row',
                    }}>
                    <FormControl id="zipcode" isInvalid={formik.touched.zipcode && !!formik.errors.zipcode}>
                        <FormLabel>Postal Code</FormLabel>
                        <Input id='zipcode' name='zipcode' value={formik.values.zipcode} onChange={formik.handleChange} placeholder="Ex: 10961" />
                        <FormErrorMessage>{formik.errors.zipcode}</FormErrorMessage>
                    </FormControl>


                    <Suspense fallback={<Spinner />}>
                        <ModalForFilter items={convertObjectToArray(countries)} isOpen={isOpen} onClose={onClose} onSelectItem={(country) => handleSelectCountry(country, formik.setFieldValue)}>
                            <CountryListItem />
                        </ModalForFilter>
                    </Suspense>

                    <FormControl isRequired isInvalid={!!formik.errors.country}>
                        <FormLabel htmlFor="country">Country</FormLabel>
                        <InputGroup >
                            {!formik?.values?.country?.length > 0 && <InputLeftElement>
                                <Icon
                                    size={'sm'}
                                    aria-label='Search Country'
                                    as={SearchIcon}
                                />
                            </InputLeftElement>}
                            <Input id="country" textAlign={'center'} isReadOnly name="country" placeholder="Country?" onClick={onOpen} value={formik?.values.country} type="text" />
                            <InputRightElement>
                                <IconButton
                                    variant={'unstyled'}
                                    onClick={onOpen}
                                    size={'sm'}
                                    icon={<ChevronDownIcon />}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{formik?.errors.country}</FormErrorMessage>
                    </FormControl>

                </Stack>
            </Stack>

            <Divider />
            <Flex
                direction="row-reverse"
                py="4"
                px={{
                    base: '4',
                    md: '6',
                }}
            >
            </Flex>
            <Box width={'100%'} mt={3} py={2}>
                <Button width={'full'} loadingText={'Submitting'} type='submit'>Next</Button>
            </Box>
        </form>

    </Box>
    )
}





const CountryListItem = ({ target, ...props }) => {//This element is gonna be cloned by the parent and pass more props

    return (<Stack shouldWrapChildren spacing="4" p={4} shadow={'md'} mb={5} rounded={'md'} cursor={'pointer'} transition={'all 0.3s'} _hover={{ boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px', }} {...props}>

        <HStack justify="space-between">
            <Badge colorScheme={'green'} size="sm">
                {target?.code}
            </Badge>
            <VStack spacing={1}>
                <Text textStyle="sm" fontWeight="medium" color="fg.emphasized">
                    {target?.native}
                </Text>

                <Text textStyle="xs" fontWeight="medium" color="fg.muted">
                    {target?.name}
                </Text>
            </VStack>

            <HStack spacing="3">
                <Avatar
                    border={'1px solid black'}
                    src={`${process.env.REACT_APP_API_URL}/static/images/flag/${target?.code?.toLocaleLowerCase().trim()}.svg`}
                    name={target?.id}
                    boxSize="16"
                />
            </HStack>
        </HStack>
    </Stack>
    )
}

const convertObjectToArray = (objct) => {
    if (Array.isArray(objct))
        return objct
    let objctArray = [];
    for (const [key, value] of Object.entries(objct)) {
        objctArray.push({ ...value, code: key })
    }
    return objctArray;

}