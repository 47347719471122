
import en_home_page from "./enHomePage";
import en_login_page from "./enLoginPage";

const enPages = {
    home:{...en_home_page},
    login:{...en_login_page}


}


export default enPages;