import { Button, Center, HStack, Icon, Square, Text, VStack } from '@chakra-ui/react'
import { FiUploadCloud } from 'react-icons/fi'
import { useDropzone } from 'react-dropzone';

export const Dropzone = (props) => {
  const { getRootProps, getInputProps, isDragActive,} = useDropzone({});

  return (
    <Center borderWidth="1px" borderRadius="lg" px="6" py="4" {...props} {...getRootProps({ className: "dropzone" })}>
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            {isDragActive ? (
              <p className="dropzone-content">
                Release to drop the files here
              </p>
            ) : (
              <p className="dropzone-content">
                Drag’n’drop some files here, or click to select files
              </p>
            )}
          </HStack>
         
          <Text textStyle="xs" color="fg.muted">
            PNG, JPG or GIF up to 2MB
          </Text>
        </VStack>
      </VStack>
    </Center>
  )
}
