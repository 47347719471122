import { Box } from "@chakra-ui/react"
import { LoginWithEmailOrProvider } from "@components/login/LoginWithEmailOrProvider"
import {  useTranslation } from "react-i18next"
import { LOGIN_HELMET_TITLE } from "src/locales/types"
import SEO from "src/searchEnigineO/SEO"


export const LoginPage = () => {
    const { t } = useTranslation();
const transPath="page.login.helmet"   
    return (<Box>
        <SEO
        title={ t(`${transPath}.${LOGIN_HELMET_TITLE}`)}
        />
        <LoginWithEmailOrProvider />
    </Box>)
}