import { Box, Button, Center, Heading, LightMode, SimpleGrid, Text } from '@chakra-ui/react'
export const HeroWithStatsBelow = ({ title = "", stats = [], action = {}, ...props }) => {

  return (
    <Box h={'100%'}>
      <Box
        as="section"
        bg="gray.800"
        _after={{
          content: `""`,
          display: 'block',
          w: 'full',
         
        
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
        py="12"
        position="relative"
        bgSize="cover"
        bgPosition="center"
        {...props}
      >
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
          h="full"
          zIndex={1}
          position="relative"
        >
          <Center flexDirection="column" textAlign="center" color="white" h="full">
            <Heading   bg= 'blackAlpha.700' p={5}  rounded={'lg'} color='cornflowerblue' size="2xl" fontWeight="extrabold">
              {title}
            </Heading>
            <LightMode>
              <Button onClick={action?.onClick} leftIcon={action?.icon} color={'black'} bgColor={'#00caff'} size="xl" mt="6" fontWeight="bold" fontSize="md">
                {action?.title}
              </Button>
            </LightMode>
          </Center>

        </Box>
        <Center>
          <Box
            rounded={'xl'}
            display={{
              base: 'none',
              md: 'block',
            }}
            position="absolute"
            zIndex={2}
            w="7xl"
            bottom="15%"
            py="4"
            bg="blackAlpha.700"

          >
            <Box
              maxW={{
                base: 'xl',
                md: '4xl',
              }}
              mx="auto"
            >
              <SimpleGrid
                spacing={3}
                columns={{
                  base: 1,
                  md: 3,
                }}
              >
                {stats.map((stat, index) =>
                  <Box key={`${stat?.name}-${index}`} textAlign="center" color="white">
                    <Text>{stat?.title}</Text>
                    <Text fontSize="3xl">{stat?.value}</Text>
                  </Box>
                )}

              </SimpleGrid>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  )
}
