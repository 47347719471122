import { toast } from "react-toastify";

const copyToClipBoard = (value) => {
    if(!!value&& typeof value==='string' && value.length>0)
    navigator.clipboard.writeText(value).then(() => {
        toast.info(value, {
            position: 'top-right',
            autoClose: '1000'
        })
    }, () => {
        alert('please press ctrl+c');
    });


}
export default copyToClipBoard;