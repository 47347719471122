
import { Avatar, Badge, HStack, Icon, Input, InputGroup, InputLeftElement, InputRightAddon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, Text, } from "@chakra-ui/react"

import { Children, cloneElement, useEffect, useRef, useState, } from "react"
import { MdSearch } from "react-icons/md"
import fuzzy from "fuzzy"
/**
 * 
 * @param placeholder Text to be show in the search bar.
 * @returns 
 */
export const ModalForFilter = ({ placeholder = 'Search here...',
    header,
    info,
    items = [],
    handleChange = () => { },
    onSelectItem = () => { },
    isLoading,
    children,
    ...rest }) => {
    const initialRef = useRef(null)
    const [value, setValue] = useState('')
    const [filter, setFilter] = useState([...items]);
    useEffect(() => {
        handleChange({ target: { value: value } })
        if (!!value && value?.length > 0) {
            var options = {
                pre: '<'
                , post: '>'
                , extract: function (el) { return `${el.code}-${el.name}` }
            };
            var results = fuzzy.filter(value, items, options)
            var matches = results.map(function (el) { return { ...el.original, string: el.string }; });
            setFilter(matches)
        } else {
            setFilter([...items])
        }

    }, [value]);

    const highlightMatch = (str) => {

        if (typeof str === 'string') {
            let sentences = str.split(/[<,>]/);
            console.log(sentences)
            return str
        }
        return str

    }
    return (
        <>
            <Modal
                isCentered
                size={'xl'}
                initialFocusRef={initialRef}
                {...rest}
            >
                <ModalOverlay />

                <ModalContent border={'unset'} >
                    {header && <ModalHeader>{header}</ModalHeader>}

                    <ModalBody p={5}  >
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={MdSearch} boxSize={35} />
                            </InputLeftElement>
                            <Input value={value} onChange={(evt) => setValue(evt.target.value)} ref={initialRef} type='text' placeholder={placeholder} />
                            <InputRightAddon>
                                {filter?.length}/{items?.length}
                            </InputRightAddon>
                        </InputGroup>
                        {!!items && items.length > 0 && <Stack
                            mt={25}
                            spacing={4}
                            p={4}
                            h={{ base: '50vh', md: '40em', lg: '35em' }}
                            overflowX={'hidden'}
                            overflowY={'scroll'}
                            position="relative"
                            rounded="lg" >
                            {isLoading ? <Spinner /> : (filter.map(target => {
                                if (!!target)
                                    if (!!children) {
                                        return Children.map(children, child => cloneElement(child, {
                                            target: target,
                                            onClick: () => onSelectItem(target),
                                            
                                        }))
                                    }
                                return (<ListItem header={target?.id} imageURL={target?.thumbnail || target?.image} description={highlightMatch(target?.string)} feature={target?.type || target?.code} key={target.id} onClick={() => onSelectItem(target)} />)
                            }
                            ))}

                        </Stack>}
                    </ModalBody>

                </ModalContent>
            </Modal >
        </>
    )


}

const ListItem = ({ id, header = '', imageURL = '', description = '', feature, ...stackProps }) => {
    return (<Stack
        shouldWrapChildren
        spacing="1"
        p={4}
        cursor={'pointer'}
        rounded="md"
        bg="bg.surface"
        boxShadow="md"
        _hover={{ bg: "brand.colors.primary" }}
        {...stackProps}>
        <Text textStyle="sm" fontWeight="medium" color="fg.emphasized">
            {header}
        </Text>
        <HStack justify="space-between">
            <Badge colorScheme={'green'} size="sm">
                {feature}
            </Badge>
            <HStack spacing="3">

                {description}

                <Avatar
                    loading="lazy"
                    src={imageURL}
                    name={id}
                    boxSize="16"
                />
            </HStack>
        </HStack>
    </Stack>)
}