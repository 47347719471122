import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'


export const TeamWithInlineHeadline = ({members=[]}) => (
  <Box >
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        spacing={{
          base: '12',
          xl: '24',
        }}
        direction={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Stack spacing="10" >
          <Stack spacing="3" maxW="sm" width="full">
            <Text
              fontSize={{
                base: 'sm',
                md: 'md',
              }}
              color="accent"
              fontWeight="semibold"
            >
              We're hiring
            </Text>
            <Stack
              spacing={{
                base: '4',
                md: '5',
              }}
            >
              <Heading
                size={{
                  base: 'sm',
                  md: 'md',
                }}
              >
                Meet our team
              </Heading>
              <Text
                fontSize={{
                  base: 'lg',
                  md: 'xl',
                }}
                color="fg.muted"
                maxW="3xl"
              >
                Discover how you can make an impact
              </Text>
            </Stack>
          </Stack>
          <Stack
            spacing="3"
            direction={{
              base: 'column-reverse',
              md: 'row',
            }}
          >
            <Button variant="secondary" size="xl">
              Contact us
            </Button>
            <Button size="xl">Join our team</Button>
          </Stack>
        </Stack>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
          }}
          columnGap="8"
          rowGap={{
            base: '10',
            lg: '12',
          }}
          flex="1"
        >
          {members.map((member) => (
            <Stack
            
              key={member.name}
              spacing={{
                base: '4',
                md: '5',
              }}
              direction="row"
            >
              <Avatar
                src={member.image}
                boxSize={{
                  base: '16',
                  md: '20',
                }}
              />
              <Stack spacing="4">
                <Stack>
                  <Box>
                    <Text fontWeight="medium" fontSize="lg">
                      {member.name}
                    </Text>
                    <Text color="accent">{member.role}</Text>
                  </Box>
                  <Text color="fg.muted">{member.description}</Text>
                </Stack>
                <HStack spacing="4" color="fg.subtle">
                  {[FaGithub, FaLinkedin, FaTwitter].map((item, id) => (
                    <Link href="#" key={id}>
                      <Icon as={item} boxSize="5" />
                    </Link>
                  ))}
                </HStack>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
)
