import { tokenConfig } from 'src/middlewares/tokenMiddleware';
import { FETCH_SERVICE_DETAILS_FAILURE, FETCH_SERVICE_DETAILS_REQUEST, FETCH_SERVICE_DETAILS_SUCCESS } from './service_types';
import axios from "axios";

export const fetchAPIserviceDetails = (service_id, paginate) => {
    let params = {
        service_id: service_id,
        page: paginate?.page,
        limit: paginate?.limit || 20,
        sortBy: paginate?.sortBy || { id: 1 },
    };

    return async (dispatch) => {
        try {


            dispatch(fetchsApiServiceDetailsRequest());
            const token = await tokenConfig(params);

            axios.get(`${process.env.REACT_APP_API_URL}/api/service`, token)
                .then(res => {
                    dispatch(fetchApiServiceDetailsSuccess({ service: res.data.service, paginate: res.data.paginate }));
                })
                .catch(({ response }) => {
                    dispatch(fetchApiServiceDetailsFailure(response?.data?.error || "An error occurred, please try again later"));

                });
        } catch (error) {
            console.log(error)
        }
    }
};


const fetchsApiServiceDetailsRequest = () => {
    return {
        type: FETCH_SERVICE_DETAILS_REQUEST
    };
};

const fetchApiServiceDetailsSuccess = ({ service, paginate }) => {
    return {
        type: FETCH_SERVICE_DETAILS_SUCCESS,
        payload: {
            service: service || {},
            paginate: paginate || {}
        }
    };
};

const fetchApiServiceDetailsFailure = error => {
    return {
        type: FETCH_SERVICE_DETAILS_FAILURE,
        payload: {
            error
        }
    };
};
