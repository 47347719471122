import { Container } from "@chakra-ui/react"
import { CtaWithImage } from "@components/callToAction/CtaWithImage"


export const MarketingPageCallToActionLayout = () => {


    return (<Container py={8}>
        <CtaWithImage />
    </Container>)
}