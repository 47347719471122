import {
    Avatar,
    Box,
    Button,
    HStack,
    Icon,
    Image,
    Skeleton,
    Stack,
    Tag,
    Text,
    useColorModeValue,
    VStack,
    Wrap,
} from '@chakra-ui/react'
import { HiCash, HiLocationMarker, HiShieldCheck } from 'react-icons/hi'
import { Card } from './Card'


export const BusinessAccountListCard = ({ account, onSelect = () => { } }) => (
    <Box as="section" w={'full'} onClick={onSelect} _hover={{
        cursor: 'pointer',
        boxShadow: 'lg',
        transition: 'all 0.3s ease-in-out'
    
    }}>
        <Card >
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '3',
                    md: '10',
                }}
                align="flex-start"
                justify={'space-between'}
            >
                <Box flexShrink={0}>
                    <Image
                        w={'15rem'}
                        h={'8rem'}
                        objectFit='contain' src={account?.image}
                        alt={account.id}
                        fallback={<Skeleton w={'15rem'} h={'8rem'} rounded={'md'} animation={'pulse'} />}
                    />
                </Box>
                <VStack spacing={8} justify={'flex-start'} alignItems="stretch">

                    <HStack alignItems={'center'} justifyContent={'flex-start'}>
                        <Icon as={HiCash} fontSize="xl" color="gray.400" />
                        <Text
                            as="h2" fontWeight="bold" fontSize="xl"
                            textTransform={'capitalize'}
                        >
                            <b>{account?.name}</b>

                        </Text>
                    </HStack>
                    <HStack>
                        <Icon as={HiShieldCheck} color={account?.status?.active ? "green.500" : "red.500"} />
                        <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color={useColorModeValue('gray.600', 'gray.300')}

                        >
                            {account?.email}
                        </Text>

                    </HStack>

                    {account?.address && <VStack spacing="1" align={'start'}>
                        <HStack>
                            <Icon as={HiLocationMarker} color="gray.400" />
                            <Text
                                fontSize="sm"
                                fontWeight="medium"
                            >
                                {account?.address?.city}
                            </Text>
                        </HStack>
                        <Text
                            fontSize="sm"
                            fontWeight="medium"
                        >
                            {account?.address?.state}, {account?.address?.country}
                        </Text>
                    </VStack>}

                </VStack>
                <Wrap shouldWrapChildren my="4" spacing="4">
                    {// <ServiceReviews reviewCount={84} rating={5.0} />
                    }




                </Wrap>
            </Stack>

        </Card>
    </Box>
)