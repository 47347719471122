import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import myTheme from './theme';
import ScrollToTop from './components/ScrollToTop';
import { initializeApp } from "firebase/app";
import store from "./redux/store";
import { Provider } from 'react-redux';
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById('root'));

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig).then;// Use any Firebase features 

getAnalytics(app);
const helmetContext = {};
root.render(
  <React.StrictMode>

    <Provider store={store}>
      <BrowserRouter>
        <ChakraProvider theme={myTheme}>
          <ScrollToTop /> {/**Scroll to top when the path change*/}
          <HelmetProvider context={helmetContext}>
            <App />
          </HelmetProvider>
        </ChakraProvider>
      </BrowserRouter>
    </Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
