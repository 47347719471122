export const categories = [
  {
    name: 'Web Design',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1705594811/Corobiz/webdisg_uni8lp.png',
    url: '#',
  },
  {
    name: 'Logo Design',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1706233780/Corobiz/logo_design_iy1gzp.jpg',
    url: '#',
  },
  {
    name: 'Graphic Design',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1706234829/Corobiz/Graphic_design-3_aruhb7.png',
    url: '#',
  },
  {
    name: 'Payment & Subscription',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1705596303/Corobiz/AdobeStock_393413448_i3ihip.jpg',
    url: '#',
  },
  {
    name: 'Online Booking',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1705596464/Corobiz/AdobeStock_111128588_epjs19.jpg',
    url: '#',
  },
  {
    name: 'Brand Kit Design',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1705599976/Corobiz/AdobeStock_110954019_kr6b9i.jpg',
    url: '#',
  },
  {
    name: 'Forms',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1706233604/Corobiz/online_forms_fruitk.png',
    url: '#',
  },

 
  {
    name: 'Live Chat',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1706316054/Corobiz/Live_Chat_500x500_xwaqwz.png',
    url: '#',
  },
]
