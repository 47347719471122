import { Box, Container, Stack } from "@chakra-ui/react"
import { ShowcaseOnSpanningColummns } from "@components/category/ShowcaseOnSpanningColummns"
import { MarketingWithScreenBelow } from "@components/heroes/MarketingWithScreenBelow"
import { MarketingPageCallToActionLayout } from "@layouts/marketingPageLayouts/MarketingPageCallToActionLayout"
import { MarketingPageBusinessBosterLayout } from "@layouts/marketingPageLayouts/MarketingPageBusinessBosterLayout"




export const MarketingServicesPage = () => {

    return (<Container>
        <Stack direction={ "column" } spacing={5}>
        <ShowcaseOnSpanningColummns />
        <MarketingPageBusinessBosterLayout />
        <MarketingPageCallToActionLayout />
        <MarketingWithScreenBelow />
    </Stack>
    </Container>)
}