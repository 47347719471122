


import { LOGOUT_USER_SUCCESS } from "../types";
import { getAuth, signOut } from "firebase/auth";
export const logout = () => dispatch => {

  const auth = getAuth();
  //sign out user and clear local storage
  signOut(auth);
  dispatch(userLogout())
 
};

const userLogout =()=> {
  
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};