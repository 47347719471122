import { Box, Button, Container, Heading, Image, Stack, Text } from '@chakra-ui/react'

export const CtaWithImage = () => (
    <Container>
      <Stack
        direction={{
          base: 'column',
          md: 'row',
        }}
        spacing={{
          base: '12',
          lg: '16',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
          width="full"
          justify="center"
        >
          <Stack
            spacing={{
              base: '4',
              md: '6',
            }}
          >
            <Heading
              size={{
                base: 'sm',
                md: 'lg',
              }}
            >
              Ready for your free trial?
            </Heading>
            <Text
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              color="fg.muted"
            >
              No credit card is required. You'll be ready to go within a few minutes. Let's go.
            </Text>
          </Stack>
          <Stack
            direction={{
              base: 'column-reverse',
              md: 'row',
            }}
            spacing="3"
          >
            <Button variant="secondary" size="xl">
              Learn more
            </Button>
            <Button size="xl">Start free trial</Button>
          </Stack>
        </Stack>
        <Image
          width="full"
          height={{
            base: 'auto',
            md: 'lg',
          }}
          objectFit="cover"
          src="https://res.cloudinary.com/druyvgiba/image/upload/v1705282144/Corobiz/brand_kit_c8vcnw.png"
        />
      </Stack>
    </Container>
  
)
