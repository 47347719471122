import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { GoogleIcon } from './ProviderIcons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik, Form, } from 'formik';
import { LoginSchema } from './LoginSchema'
import { login } from "@redux/actions/user-actions/loginAction";
import { toast } from 'react-toastify'
import { Logo } from '@components/CoroBizLogo'
import { Trans } from 'react-i18next'
import { LOGIN_FORM_GOOGLE_BUTTON, LOGIN_FORM_ISSUES_OPTION, LOGIN_FORM_ISSUES_QUESTION, LOGIN_FORM_LABEL_EMAIL, LOGIN_FORM_LABEL_PASSWORD, LOGIN_FORM_NOT_REGISTERED_OPTION, LOGIN_FORM_NOT_REGISTERED_QUESTION, LOGIN_FORM_PLACEHOLDER_EMAIL, LOGIN_FORM_REMEBER_THIS_DEVICE, LOGIN_FORM_SUBMIT_BUTTON, LOGIN_FORM_TITLE } from '../../locales/types'


export const LoginWithEmailOrProvider = () => {
  const emailRef = useRef();
  const { user, error, loading } = useSelector(state => state.userrr)

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const transPath = "page.login.form"
  useEffect(() => {
    if (!!user) {
      navigate('/')
    }
  }, [user, navigate])

  const handleFocus = (event) => event.target.select();
  const handleChangeField = (evt, handle = () => { }) => {
 
    handle(evt)
  }
  const initialValues = { email: '', password: '' }

  return (<Container
    maxW="md"
    py={{
      base: '6',
      md: '12',
    }}
  >

    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values, { resetForm }) => {
        toast.promise(
          dispatch(login(values.email, values.password)),
          {
            pending: 'Authenticating...',
            success: 'Login successfull👌',
            error: {
              render({ data }) {
             
                // When the promise reject, data will contains the error
                return data?.message
              }
            }
          }
        )


      }}
    >
      {({ handleChange, handleBlur, values, errors, touched }) => (
        <Stack spacing="8" position={'relative'}>
          <Stack spacing="6">
            <Stack
              spacing={{
                base: '2',
                md: '3',
              }}
            >
              <Center>
                <VStack>
                  <Logo h='8.6em' w='8.6em' />
                  <Heading
                    size={{
                      base: 'xs',
                      md: 'sm',
                    }}
                  >
                    <Trans i18nKey={`${transPath}.${LOGIN_FORM_TITLE}`} />
                  </Heading>
                  <Text color="fg.muted">
                    <Trans i18nKey={`${transPath}.${LOGIN_FORM_NOT_REGISTERED_QUESTION}`} />
                    <Link onClick={() => navigate('/registration')}>
                      <span className="text-primary hover:underline cursor-pointer"> </span>
                      <Trans i18nKey={`${transPath}.${LOGIN_FORM_NOT_REGISTERED_OPTION}`} />
                    </Link>
                  </Text>
                </VStack>
              </Center>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Stack spacing="4">
              <Alert status='error' display={(!!error?.message) ? 'flex' : 'none'}>
                <AlertIcon />
                <AlertDescription>{error?.message}</AlertDescription>
              </Alert>
              <Form>
                <VStack spacing={5} >
                  {error && <Heading status="error" borderRadius={8} color='black'>
                    {error.errorMessage}
                  </Heading>}

                  <FormControl isInvalid={errors.email}>
                    <FormLabel><Trans i18nKey={`${transPath}.${LOGIN_FORM_LABEL_EMAIL}`} /></FormLabel>
                    <Input ref={emailRef} onFocus={handleFocus} data-testid='email-input'
                      onChange={(evt) => handleChangeField(evt, handleChange)} onBlur={handleBlur} name="email" type='email' value={values.email} />

                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl display={!!values.email && !errors.email ? 'block' : 'none'} isInvalid={touched.password && errors.password}>
                    <FormLabel> <Trans i18nKey={`${transPath}.${LOGIN_FORM_LABEL_PASSWORD}`} /></FormLabel>
                    <Input name='password' value={values.password} onChange={(evt) => handleChangeField(evt, handleChange)} onBlur={handleBlur} type='password' />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                </VStack>

                <VStack spacing={4} m={5} display={'flex'}>
                  <Checkbox alignSelf={'start'} margin={'auto'}> <Trans i18nKey={`${transPath}.${LOGIN_FORM_REMEBER_THIS_DEVICE}`} /></Checkbox>
                  <Center>
                    <Button isLoading={loading} width={'sm'} type='submit' _hover={{ rounded: 'full', bgColor: 'green.400' }}>
                      <Trans i18nKey={`${transPath}.${LOGIN_FORM_SUBMIT_BUTTON}`} />
                    </Button>
                  </Center>
                </VStack>
              </Form>
            </Stack>


            <HStack>
              <Divider />
              <Icon as={GoogleIcon} />
              <Divider />
            </HStack>
            <Stack spacing="3">
              <Button isDisabled={loading} variant="secondary" leftIcon={<GoogleIcon />}>
                <Trans i18nKey={`${transPath}.${LOGIN_FORM_GOOGLE_BUTTON}`} />
              </Button>

            </Stack>
            <Divider />


            <HStack spacing="1" justify="center">
              <Text textStyle="sm" color="fg.muted" textAlign={'center'}>
                <Trans i18nKey={`${transPath}.${LOGIN_FORM_ISSUES_QUESTION}`} />
                <Link href="/auth/recovery"   >
                  <Trans i18nKey={`${transPath}.${LOGIN_FORM_ISSUES_OPTION}`} />
                </Link>
              </Text>
            </HStack>

          </Stack>

        </Stack>)}
    </Formik>
  </Container>
  )
}
