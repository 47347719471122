import { Box, Container, Stack } from '@chakra-ui/react'
import { Step } from '@components/steps/Step'

export const SignupFormStpesLayout = ({steps=[],setStep=()=>{},currentStep=0}) => {

  return (
   <Container>
        <Stack
          maxW={'100%'}
          spacing="0"
          direction={{
            base: 'row',
            md: 'column',
          }}
      
        >
          {steps.map((step, id) => (
            <Step
              key={id}
              cursor="pointer"
              onClick={() => setStep(id)}
              title={step.title}
              description={step.description}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
              isFirstStep={id === 0}
              isLastStep={steps.length === id + 1}
            />
          ))}
        </Stack>
      </Container>
   
  )
}
