
//Phone number should be  in the format of +123 456 7890
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


//Domain name should be only  alphanumeric characters and hyphens, with no leading or trailing whitespace.
export const DOMAIN_REGEX = /^[a-z0-9_.]*$/



// Country code should contain exactly 2 uppercase characters from A..Z
export const COUNTRY_CODE_REGEX = /^[A-Z]{2}$/