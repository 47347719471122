import {

} from "../types";
import { tokenConfig } from "@middlewares/tokenMiddleware";
import axios from "axios";
import { toast } from "react-toastify";
import { FETCH_SERVICES_FAILURE, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS } from "./service_types";

export const fetchAPIservices = (paginate) => {
  let params = {
    page: paginate?.page,
    limit: paginate?.limit || 20,
    sortBy: paginate?.sortBy || { id: 1 },
  };
 
  return async (dispatch) => {
    const token = await tokenConfig(params);
    dispatch(fetchsApiServicesRequest());
    axios.get(`${process.env.REACT_APP_API_URL}/api/service/list`, token)
      .then(res => {
        dispatch(fetchApiServicesSuccess({ list: res.data.list, paginate: res.data.paginate }));
      })
      .catch(err => {
      
        dispatch(fetchApiServicesFailure(err.error));
      });
  };
};

const fetchsApiServicesRequest = () => {
  return {
    type: FETCH_SERVICES_REQUEST
  };
};

const fetchApiServicesSuccess = ({ list, paginate }) => {
  return {
    type: FETCH_SERVICES_SUCCESS,
    payload: {
      apiServices: list || [],
      paginate: paginate || {}
    }
  };
};

const fetchApiServicesFailure = error => {
  return {
    type: FETCH_SERVICES_FAILURE,
    payload: {
      error
    }
  };
};
