import { Loader } from '@googlemaps/js-api-loader';


let googlePlaces;
/**
 * @description Load Goole Maps Libraries from @googlemaps/js-api-loader src: ./@libs/google/googleApiClient
 * @default googlePlace Google Place Client Library load with the param (places)
 * @returns Async function getGoogleMapsApiClient() that already loaded the Places library from @googlemaps/js-api-loader
 * @protected
 * 
 */
export async function getGoogleMapsApiClient() {
    if (googlePlaces) {
        return googlePlaces;
    }

    const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        version: "weekly",

    });



    googlePlaces = (await loader.importLibrary('places'));

    return googlePlaces;
}
getGoogleMapsApiClient()

export default googlePlaces;