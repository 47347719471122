import { Avatar, Badge, Box, Button, Center, Divider, FormControl, FormErrorMessage, FormLabel, HStack, Heading, Icon, IconButton, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, ListItem, Spinner, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react"
import { Form, Formik } from 'formik'

import { businessSchema } from "./ResgisterFormSchema";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {  useEffect, useState } from "react";
import { tokenConfig } from "../../middlewares/tokenMiddleware";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";



export const BusinesRegistrationFomr = ({
    defaultValues,
    onSubmit = () => { },
    ...props }) => {

    const { bn: businessNameParam, bd: businessDomainParam } = useParams()
    const [isDomainAvailable, setIsDomainAvailable] = useState(false)

    const handleChangeBusinesName = (evt, setFieldValue) => {
        try {
            setFieldValue('businessName', evt?.target?.value);
            //Set also the business domain field with the business name value and replace any symbol or space by an underscore
            handleChangeBusinesDomain(evt, setFieldValue)
        } catch (error) {

            toast.error('Something went wrong trying to set the business name.')
        }


    }

    useEffect(() => { validateDomain(businessDomainParam) },
        [businessDomainParam])

    const validateDomain = async (domain) => {
        if (!!domain && domain?.trim()?.length >= 2) {
            const token = await tokenConfig();
            axios.get(`${process.env.REACT_APP_API_URL}/api/business/check_domain?domain=${encodeURIComponent(domain)}`, token)
                .then(res => {
                    if (!!res) {
                        const message = res?.data?.message;
                        const transCode = res?.data?.transCode;
                    }
                    setIsDomainAvailable(true)
                })
                .catch(err => {

                    let errorMessge = err?.response?.data?.message || err.message;

                    setIsDomainAvailable(false);
                });

        }
    }


    const handleChangeBusinesDomain = (evt, setFieldValue) => {
        try {
            //Set business domain field and replace any symbol or space by an underscore
            const domain = evt.target?.value.replace(/[^A-Z0-9]+/ig, "_")?.toLowerCase()?.trim()
            setFieldValue('businessDomain', domain)
            validateDomain(domain)

        } catch (error) {
            toast.error('Something went wrong trying to set the business domain.')
        }



    }

    return (<Box mx={3} {...props}>
        <Center>
            <Heading as={'h4'} size={'xs'}>Business Information</Heading>
        </Center>
        <Divider my={5} />
        <Formik
            validationSchema={businessSchema}
            onSubmit={(values) => onSubmit(values)}
            initialValues={{
                businessName: businessNameParam || defaultValues?.businessName || '',
                businessDomain: businessDomainParam?.toLocaleLowerCase() || defaultValues?.businessDomain?.toLocaleLowerCase() || '',
            }}
        >
            {({
                values,
                setFieldValue,
                touched,
                errors,
            }) => (<Form>
                <Stack spacing="3" >
                    <FormControl isRequired isInvalid={touched.businessName && errors.businessName}>
                        <FormLabel htmlFor="businessName">Business Name</FormLabel>
                        <Input id="businessName" name="businessName" value={values.businessName} onChange={(evt) => handleChangeBusinesName(evt, setFieldValue, values)} type="text" />
                        <FormErrorMessage>{errors.businessName}</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.businessDomain}>
                        <FormLabel htmlFor="businessDomain">Domain</FormLabel>
                        <InputGroup >
                            <InputLeftAddon backgroundColor={!isDomainAvailable || errors.businessDomain ? 'red' : 'green'}>
                                <Icon as={!isDomainAvailable || errors.businessDomain ? MdCancel : FaCheck} color={'white'} />
                            </InputLeftAddon>
                            <Input textAlign={'end'} id="businessDomain" name="businessDomain" value={values.businessDomain} onChange={evt => handleChangeBusinesDomain(evt, setFieldValue)} type="text" />
                            <InputRightAddon fontSize={'smaller'} color={'fg.muted'}>
                                /corobiz.com
                            </InputRightAddon>
                        </InputGroup>
                        <FormErrorMessage>{errors.businessDomain}</FormErrorMessage>
                    </FormControl>

                </Stack>

                <Box width={'100%'} mt={3} py={2}>
                    <Button width={'full'} loadingText={'Submitting'} type='submit'>Next</Button>
                </Box>
            </Form>)}
        </Formik >
    </Box>)
}

const CountryListItem = ({ target, ...props }) => {//This element is gonna be cloned by the parent and pass more props

    return (<Stack shouldWrapChildren spacing="4" p={4} shadow={'md'} mb={5} rounded={'md'} cursor={'pointer'} transition={'all 0.3s'} _hover={{ boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px', }} {...props}>

        <HStack justify="space-between">
            <Badge colorScheme={'green'} size="sm">
                {target?.code}
            </Badge>
            <VStack spacing={1}>
                <Text textStyle="sm" fontWeight="medium" color="fg.emphasized">
                    {target?.native}
                </Text>

                <Text textStyle="xs" fontWeight="medium" color="fg.muted">
                    {target?.name}
                </Text>
            </VStack>

            <HStack spacing="3">
                <Avatar
                    border={'1px solid black'}
                    src={`${process.env.REACT_APP_API_URL}/static/images/flag/${target?.code?.toLocaleLowerCase().trim()}.svg`}
                    name={target?.id}
                    boxSize="16"
                />
            </HStack>
        </HStack>
    </Stack>
    )
}


const convertObjectToArray = (objct) => {
    if (Array.isArray(objct))
        return objct
    let objctArray = [];
    for (const [key, value] of Object.entries(objct)) {
        objctArray.push({ ...value, code: key })
    }
    return objctArray;

}