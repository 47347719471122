import { Box, Button, Center, Divider, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack } from "@chakra-ui/react"
import { Form, Formik } from 'formik'
import { loginRegisterSchema } from "./ResgisterFormSchema"
import { useEffect, useState } from "react"
import { tokenConfig } from "src/middlewares/tokenMiddleware"
import axios from "axios"
import { toast } from "react-toastify"




export const LoginInfoForm = ({
    defaultValues = {
        name: '',
        email: '',
    }
    ,
    onSubmit = (values) => { console.log(values) },
    ...props
}) => {

    const [isValidatingEmail, setIsValidatingEmail] = useState(false)

    const validateEmail = async (email) => {

        if (!!email && email?.trim()?.length >= 2) {
            setIsValidatingEmail(true)
            const token = await tokenConfig();
            return axios.get(`${process.env.REACT_APP_API_URL}/api/user/email/available?email=${encodeURIComponent(email)}`, token)
                .then(res => {
                    if (!!res) {
                        const message = res?.data?.message;
                        const transCode = res?.data?.transCode;
                    }
                    setIsValidatingEmail(false)
                    return true
                })
                .catch(err => {
                    
                    setIsValidatingEmail(false)
                    return false;
                });

        }
    }

    const checkEmailAndSubmit = async (values) => {
        const isValidate = await validateEmail(values.email);
        if (isValidate) {
            onSubmit(values);
        } else {
            toast.warning("Email Address Unavailable");

        }

    }

    return (<Box {...props}>
        <Center>
            <Heading as={'h4'} size={'xs'}>Access Information</Heading>
        </Center>
        <Divider my={5} /><Formik
            validationSchema={loginRegisterSchema}
            onSubmit={(values) => checkEmailAndSubmit(values)}
            initialValues={{
                ...defaultValues,
                password: '',
                confirm_password: ''
            }}
        >
            {({
                handleChange,
                values,
                touched,
                errors,
            }) => (<Form>
                <Stack spacing="3" >
                    <FormControl isRequired isInvalid={touched.name && errors.name}>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input id="name" name="name" value={values.name} onChange={handleChange} type="text" />
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={touched.email && errors.email}>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input id="email" name="email" value={values.email} onChange={handleChange} type="email" />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={touched.password && errors.password}>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <Input id="password" name="password" value={values.password} onChange={handleChange} type="password" />
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={touched.confirm_password && errors.confirm_password}>
                        <FormLabel htmlFor="confirm_password">Confirm Password</FormLabel>
                        <Input id="confirm_password" name="confirm_password" value={values.confirm_password} onChange={handleChange} type="password" />
                        <FormErrorMessage>{errors.confirm_password}</FormErrorMessage>
                    </FormControl>

                </Stack>
                <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} padding={8}>
                    <Button isLoading={isValidatingEmail} width={'8rem'} loadingText={'Validating Email'} type='submit'>Next</Button>
                </Box>
            </Form>)}
        </Formik>
    </Box>)
}