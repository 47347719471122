
import { Box, FormControl, FormLabel, StackDivider, Input, InputGroup, InputLeftElement, Stack, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'; // Add useEffect and useState

const AddressInput = ({ address, onChange = () => { }, onSave = () => { }, isReadOnly= true }) => {
    const [addressValues, setAddressValues] = useState(address);

    useEffect(() => {
        if (address) { setAddressValues(address) };
    }, [address]);


    // Your code here

    return (<Stack spacing={5} >
        <FormControl id="address">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">Address</FormLabel>
                <InputGroup>
                    <Input
                        isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.address || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="address_one">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">Apt/Suite</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.address_one || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="city">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">City</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.city || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="state">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">State</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.state || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="country">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">Country</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.country || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="zipcode">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">Zipcode</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.zipcode || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
        <FormControl id="note">
            <Stack
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                spacing={{
                    base: '1.5',
                    md: '8',
                }}
                justify="space-between"
            >
                <FormLabel variant="inline">Note</FormLabel>
                <InputGroup>
                    <Input
                      isReadOnly={isReadOnly}
                        type="text"
                        maxW={{
                            md: '2xl',
                        }}
                        defaultValue={address?.note || ''}
                    />
                </InputGroup>
            </Stack>
        </FormControl>
    </Stack>

    );
};

export default AddressInput;