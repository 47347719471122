import { IconButton } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

export const ColorModeToggleButton = (props) => {
    const { toggleColorMode, colorMode } = useColorMode();
    return (<IconButton
    variant={'ghost'}
        {...props}
       zIndex={100}
        onClick={toggleColorMode}
        icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
    />)
}