import { tokenConfig } from "@middlewares/tokenMiddleware";
import { REGISTER_USER_FAILURE, REGISTER_USER_SUCCESS } from "../types";
import axios from "axios";

export const registerNewUser = info => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    const token = await tokenConfig(null, { "check-action-name": "signup" });
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/register`, info, token)
      .then(res => {
        if (!!res) {
          const user = res?.data?.user;
          const message = res?.data?.message;
          const token = res?.data?.token;
          dispatch(registerUserSuccess(user, message, token));
          resolve(res);
        } else {
          resolve({message:'Business registered successfully'})
        }
      })
      .catch(err => {

        let errorMessge = err?.response?.data?.message||err.message;
       
        dispatch(registerUserFailure(errorMessge));
        reject(errorMessge);
      });
  
  
    });
};

const registerUserSuccess = (user, successMessage, token) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: {
      user,
      successMessage,
      token
    }
  };
};

const registerUserFailure = error => {
  return {
    type: REGISTER_USER_FAILURE,
    payload: {
      error
    }
  };
};
