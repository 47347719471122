import { combineReducers } from "redux";
import userReducer from "./userReducer";
import APIservicesReducer from "./APIservicesReducer";
import businessReducer from "./businessReducer";



const rootReducer = combineReducers({
  userrr: userReducer,
  apiServicesss: APIservicesReducer,
  businesss: businessReducer,
});

export default rootReducer;
