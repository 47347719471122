import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { AccountSwitcherButton } from './AccountSwitcherButton'
import { useSelector } from 'react-redux'
import { ButtonLogout } from '../button/ButtonLogout'

export const AccountSwitcher = () => {
  const { user, loading } = useSelector(state => state.userrr)
  return (
    <Menu>
      <AccountSwitcherButton user={user} />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        <Text fontWeight="medium" mb="2">
          {user?.email}
        </Text>
        <MenuOptionGroup defaultValue="chakra-ui">
          <MenuItemOption value="chakra-ui" fontWeight="semibold" rounded="md">
            Admin Console
          </MenuItemOption>
          <MenuItemOption value="careerlyft" fontWeight="semibold" rounded="md">
            Tickects
          </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem rounded="md">Workspace settings</MenuItem>
        <MenuItem rounded="md">Edit account</MenuItem>
        <MenuDivider />
        <ButtonLogout />
      </MenuList>
    </Menu>
  )
}
