export const links = [
  {
    title: 'Company',
    links: [
     
      {
        label: 'About Us',
        href: '#',
      },
      {
        label: 'Careers',
        href: '#',
      },
     
    
    ],
  },
  {
    title: 'Services',
    links: [
      {
        label: 'How it works',
        href: '#',
      },
      {
        label: 'Pricing',
        href: '#',
      },
      {
        label: 'Use Cases',
        href: '#',
      },
      {
        label: 'Integrations',
        href: '#',
      },
      {
        label: 'SAML SSO',
        href: '#',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        label: 'Blog',
        href: '#',
      },
      {
        label: 'Partnerships',
        href: '#',
      },
      {
        label: 'Case studies',
        href: '#',
      },
      {
        label: 'Media Assets',
        href: '#',
      },
      {
        label: 'FAQs',
        href: '#',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        label: 'Terms of Service',
        href: '#',
      },
      {
        label: 'Privacy Policy',
        href: '#',
      },
      {
        label: 'Offer terms',
        href: '#',
      },
      {
        label: 'License',
        href: '#',
      },
    ],
  },
]
