import { ACCESSIBLE_DESCRIPTION, ACCESSIBLE_HEADER, ADMIN_DASHBOARD_DESCRIPTION, ADMIN_DASHBOARD_HEADER, BUSINESS_PROMOTION_DESCRIPTION, BUSINESS_PROMOTION_HEADER, DASHBOARD_BUTTON, FEATURES_SECTION_HEADER, LOGIN_HELMET_TITLE, ONLINE_BOOKING_DESCRIPTION, ONLINE_BOOKING_HEADER, PAYMENT_DESCRIPTION, PAYMENT_HEADER, SMS_EMAIL_NOTIFICATIONS_DESCRIPTION, SMS_EMAIL_NOTIFICATIONS_HEADER,  WEBSITE_TEMPLATES_DESCRIPTION, WEBSITE_TEMPLATES_HEADER, WELCOME_BUSINESS_DESCRIPTION, WELCOME_BUSINESS_HEADER } from "../types";



const es_home_page = {
    helmet:{
        [LOGIN_HELMET_TITLE]:"CoroBiz",
      },
    section: {//Sections  within the Home page
        main: {//Main  Section of the Home Page
            [WELCOME_BUSINESS_HEADER]: `Es más que un sitio web, es un reflejo de tu futuro!`,
            [WELCOME_BUSINESS_DESCRIPTION]: `Ofrecemos una gama de servicios que están diseñados para dar a su empresa el impulso que necesita para tener éxito. Lleva tu negocio al siguiente nivel.`,
            [DASHBOARD_BUTTON]: 'Panel de Control',

        },
        features: {//Features part of the Home Page.
            [FEATURES_SECTION_HEADER]: "Prestaciones",
            [ONLINE_BOOKING_HEADER]: 'Reservaciones en línea',
            [ONLINE_BOOKING_DESCRIPTION]: 'Obten tu propio sitio web de reservaciones o integra uno ya existente. Nuestros servicios le permiten enlazar cuentas de Facebook, Google, Instagram, TikTok, WhatsApp ...',
            [BUSINESS_PROMOTION_HEADER]: 'Promoción de tu negocio',
            [BUSINESS_PROMOTION_DESCRIPTION]: 'Cree sin esfuerzo su próxima promoción con CoroBiz y vea cómo su negocio arrasa en el mercado.',
            [ADMIN_DASHBOARD_HEADER]: 'Panel de administración',
            [ADMIN_DASHBOARD_DESCRIPTION]: 'Tome el control de su negocio con facilidad, ya sea que esté en movimiento o en la oficina, con nuestro panel de administración personalizado y nuestra aplicación de administración eficiente.',
            [SMS_EMAIL_NOTIFICATIONS_HEADER]: "Notificaciones por SMS y correo electrónico",
            [SMS_EMAIL_NOTIFICATIONS_DESCRIPTION]: "Mantente al tanto de tu negocio con notificaciones en tiempo real de toda la actividad, desde nuevas reservas hasta mensajes de los clientes.",
            [PAYMENT_HEADER]: "Integración de pasarelas de pago ",
            [PAYMENT_DESCRIPTION]: "Ofrecemos una gama de pasarelas de pago seguras, incluidas plataformas confiables como PayPal, Apple Pay y Google Pay. Para explorar la lista completa de nuestras opciones de pago admitidas, simplemente haga clic en el botón a continuación.",
            [WEBSITE_TEMPLATES_HEADER]: "Nuestras plantillas",
            [WEBSITE_TEMPLATES_DESCRIPTION]: "Nuestra selección de plantillas profesionales e intuitivas, elaboradas por expertos, te permitirá alcanzar tus objetivos sin esfuerzo.",
            [ACCESSIBLE_HEADER]: "Accesible para todos",
            [ACCESSIBLE_DESCRIPTION]: "Creemos en la creación de un mundo en el que todas las personas tengan el mismo acceso a la información, y nuestros sitios web están diseñados con esta visión en mente. Nos esforzamos por hacer que nuestras plataformas digitales sean accesibles para todos, independientemente de sus capacidades.",
        }
    }

}


export default es_home_page;