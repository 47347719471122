import { Container } from "@chakra-ui/layout"
import { SupportPageMainLayout } from "@layouts/supportPageLayouts/SupportPageMainLayout"
import { SupportPageFormLayout } from "../layouts/supportPageLayouts/SupportPageFormLayout"
import { ServicePageCategoriesLayout } from "../layouts/servicePageLayouts/ServicePageCategoriesLayout"



export const ServicesPage = () => {



    return (<Container>
        <ServicePageCategoriesLayout />
    </Container>)
}