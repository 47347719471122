import { Box, Heading, Image, LightMode, Skeleton, Stack, Text } from '@chakra-ui/react'

export const ImageWithOverlay = (props) => {
  const {
    title,
    description,
    url,
    src,
    alt,
    spacing = '8',
    objectPosition = 'cover',
    ...rest
  } = props
  return (
    <Box borderRadius="xl" overflow="hidden" position="relative" width="full" {...rest}>
      <Image
      
        boxSize="full"
        maxHeight={{
          base: '240px',
          md: '100%',
        }}
        src={src}
        alt={alt}
        objectFit="cover"
        objectPosition={objectPosition}
        fallback={<Skeleton />}
      />
      <Box
        position="absolute"
        inset="0"
       
        px={{
          base: '6',
          md: '10',
        }}
        py={{
          base: '6',
          md: '10',
        }}
        boxSize="full"
      >
        {!!title&&<Stack rounded={'lg'} p={2} spacing={spacing}  bgGradient="linear(to-t, blackAlpha.700 20%, blackAlpha.800)">
          <Stack spacing="4" textAlign={'center'}>
            <Heading size="lg" color="white">
              {title}
            </Heading>
            {description && (
              <Text fontSize="lg" textAlign={'start'} color="white" maxW="2xs">
                {description}
              </Text>
            )}
          </Stack>
          
        </Stack>}
      </Box>
    </Box>
  )
}
