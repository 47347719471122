import { AddIcon, EditIcon, ExternalLinkIcon, RepeatIcon } from '@chakra-ui/icons'
import { Box, Heading, HStack, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Square, Stack, Text } from '@chakra-ui/react'
import { FiArrowDownRight, FiArrowUpRight, FiMoreVertical } from 'react-icons/fi'

export const Stat = (props) => {
  const { label, value, icon, delta, ...boxProps } = props
  return (
    <Box
      px={{
        base: '4',
        md: '6',
      }}
      py={{
        base: '5',
        md: '6',
      }}
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="sm"
      {...boxProps}
    >
      <Stack
        spacing={{
          base: '5',
          md: '6',
        }}
      >
        <Stack direction="row" justify="space-between">
          <HStack spacing="4">
            <Square size="12" bg="bg.accent.subtle" borderRadius="md">
              <Icon as={icon} boxSize="6" color="fg.accent.default" />
            </Square>
            <Text fontWeight="medium">{label}</Text>
          </HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              variant={'ghost'}
              rounded={'full'}
              icon={<FiMoreVertical />}
              size={'md'}
              color="fg.muted" />
            <MenuList>
              <MenuItem icon={<AddIcon />} command='⌘T'>
                New Tab
              </MenuItem>
              <MenuItem icon={<ExternalLinkIcon />} command='⌘N'>
                New Window
              </MenuItem>
              <MenuItem icon={<RepeatIcon />} command='⌘⇧N'>
                Open Closed Tab
              </MenuItem>
              <MenuItem icon={<EditIcon />} command='⌘O'>
                Open File...
              </MenuItem>
            </MenuList>
          </Menu>

        </Stack>
        <Stack spacing="4">
          <Heading
            size={{
              base: 'sm',
              md: 'md',
            }}
          >
            {value}
          </Heading>
          <HStack spacing="1" fontWeight="medium">
            <Icon
              color={delta.isUpwardsTrend ? 'success' : 'error'}
              as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}
              boxSize="5"
            />
            <Text color={delta.isUpwardsTrend ? 'success' : 'error'}>{delta.value}</Text>
            <Text color="fg.muted">vs last week</Text>
          </HStack>
        </Stack>
      </Stack>
    </Box>
  )
}
