import * as yup from 'yup'
import { DOMAIN_REGEX } from '@utils/regex';


export const loginRegisterSchema = yup.object().shape({
    name: yup.string().required().min(1).max(25),
    email: yup.string().required().email(),
    password: yup.string().required()
        .min(8, 'Password is too short - should be 8 chars minimum.').max(35),
    confirm_password: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match'),
});



export const validFileExtensions = { image: ['image/jpg', 'image/gif', 'image/png', 'image/jpeg', 'image/svg', 'image/webp'] };

function isValidFileType(type, fileType) {
    const maxSizeKB = 5000
    return type && validFileExtensions[fileType].indexOf(type) > -1;
    //* businessPhone: yup.string().required("Required").matches(phoneRegExp, "Must be a valid email phone number"),
    /*  businessLogo: yup.mixed()
           .test("is-valid-type", "Not a valid image type", value => isValidFileType(value && value?.file?.type?.toLowerCase(), "image"))
           .test("is-valid-size", `Max allowed size is ${maxSizeKB}KB`, value => value && value?.file?.size <= maxSizeKB * 1024 * 1024),*/
}


export const businessSchema = yup.object().shape({
    businessName: yup.string().required("Required").min(2).max(35),
    businessDomain: yup.string().required("Required").min(1).max(35).matches(DOMAIN_REGEX, 'Inavlid Domain'),
    website: yup.string().optional().min(1).max(75),
});
export const addressSchema = yup.object().shape({
    address: yup.string().min(5).max(80).required(),
    city: yup.string().min(5).max(25).required(),
    state: yup.string().min(2).max(25).required(),
    zipcode: yup.string().min(3).max(25).optional(),
    country: yup.string().required().min(1).max(30),
    note: yup.string().optional().min(1).max(250),
});


