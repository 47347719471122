import { WarningIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Icon, Img, Text, useMenuButton } from '@chakra-ui/react'
import { ShieldCheck } from 'react-bootstrap-icons'
import { HiSelector } from 'react-icons/hi'

export const AccountSwitcherButton = ({ user = {}, ...props }) => {
  const buttonProps = useMenuButton(props)
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg="gray.700"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.3s  ease-in-out"
      _active={{
        bg: 'gray.600',
      }}
      _focus={{
        shadow: 'outline',
      }}
    >
      <HStack flex="1" spacing="3">
        <Img
         display={user?.image? 'block':'none'}
          w="8"
          h="8"
          rounded="md"
          objectFit="cover"
          src={user?.image}
          alt='User Avatar'
        />
        <Box textAlign="start">
          <Box noOfLines={1} fontWeight="semibold">
            {user?.displayName}
          </Box>
          <Box fontSize="xs" color="gray.400">
            <Icon as={user?.emailVerified ? ShieldCheck : WarningIcon} mr="1" color={user.emailVerified ? 'green' : 'orange'} />
            <Text>
              {user?.email}
            </Text>
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  )
}
