import { WarningIcon } from '@chakra-ui/icons'
import {

    Box,
    Button,
    Container,
    Flex,
    Heading,
    Spinner,
    Stack,
    VStack,

} from '@chakra-ui/react'
import { BusinessAccountListCard } from '@components/card/BusinessAccountListCard'
import { BusinessAccountDetails } from '@layouts/businessAccountLayouts/BusinessAccountDetailsLayout'
import { fetchBusinessAccounts } from '@redux/actions/business-actions/fetchAllBusinessAccounts'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

export const BusinessAccountPage = () => {




    
    const { business, loading } = useSelector((states) => states.businesss)
    const dispatch = useDispatch();
    const handleFetch = () => {
        if (!loading)
            toast.promise(
                dispatch(fetchBusinessAccounts()),
                {
                    pending: 'Retriving your account/s, please wait...',
                    success: 'Done!',
                    error: {
                        render({ data }) {
                            // When the promise reject, data will contains the error
                            return data?.response?.data?.message || data?.response?.data || data?.message || "An unexpected error occurred"
                        }
                    }
                }, {
                autoClose: 3000,

            }
            )

    }
    useEffect(() => {
        handleFetch();
    }, [])
    if (loading)
        return <Container>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} w={'100%'} h={'70vh'}>
                <VStack>
                    <Spinner size="xl" colorScheme='teal' />
                    <Heading as={'h2'} size={'md'}>Loading...</Heading>
                </VStack>
            </Box>
        </Container>;
    return (<Container
        maxW="container.lg"
        py={{
            base: '4',
            md: '8',
        }}
    >
        <Stack spacing={6}>
            {business?.map(account => <BusinessAccountListCard account={account} key={account._id} />)}
            <Flex w='100%' justifyContent='flex-end'>
                <Button onClick={handleFetch} isLoading={loading} colorScheme='teal'>Load More</Button>
            </Flex>
        </Stack>
        <BusinessAccountDetails business={business[0]} />

    </Container>
    )
}