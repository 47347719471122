// service_types.js

// Types for fetching data
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';

// Types for creating data
export const SERVICE_SUBSCRIPTION_REQUEST = 'SERVICE_SUBSCRIPTION_REQUEST';
export const SERVICE_SUBSCRIPTION_SUCCESS = 'SERVICE_SUBSCRIPTION_SUCCESS';
export const SERVICE_SUBSCRIPTION_FAILURE = 'SERVICE_SUBSCRIPTION_FAILURE';


// Types for canceling subscription
export const CANCEL_SERVICE_SUBSCRIPTION_REQUEST = 'CANCEL_SERVICE_SUBSCRIPTION_REQUEST';
export const CANCEL_SERVICE_SUBSCRIPTION_SUCCESS = 'CANCEL_SERVICE_SUBSCRIPTION_SUCCESS';
export const CANCEL_SERVICE_SUBSCRIPTION_FAILURE = 'CANCEL_SERVICE_SUBSCRIPTION_FAILURE';


// Types for fetching service details
export const FETCH_SERVICE_DETAILS_REQUEST = 'FETCH_SERVICE_DETAILS_REQUEST';
export const FETCH_SERVICE_DETAILS_SUCCESS = 'FETCH_SERVICE_DETAILS_SUCCESS';
export const FETCH_SERVICE_DETAILS_FAILURE = 'FETCH_SERVICE_DETAILS_FAILURE';
