import { NAVBAR_LINK_DASHBOARD, NAVBAR_LINK_HOME, NAVBAR_LINK_LOGIN, NAVBAR_LINK_MARKETING, NAVBAR_LINK_PRICING, NAVBAR_LINK_SERVICES, NAVBAR_LINK_SIGNUP, NAVBAR_LINK_SUPPORT } from "../types";

const enComponents= {
    navbar: {
        [NAVBAR_LINK_HOME]: "Home",
        [NAVBAR_LINK_PRICING]: "Pricing",
        [NAVBAR_LINK_SERVICES]: "Services",
        [NAVBAR_LINK_MARKETING]: "Marketing",
        [NAVBAR_LINK_SUPPORT]: "Suport",
        [NAVBAR_LINK_LOGIN]: "Log in",
        [NAVBAR_LINK_SIGNUP]: "Sign Up",
        [NAVBAR_LINK_DASHBOARD]:"Dasboard"
    }
}


export default enComponents;

