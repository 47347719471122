import {
  Box,
  Circle,
  Flex,
  Stack,
  Heading,
  Button,
} from '@chakra-ui/react'
import {
  BiAddToQueue,
  BiBuoy,
  BiCog,
  BiCommentAdd,
  BiEnvelope,
  BiHome,
  BiNews,
  BiPurchaseTagAlt,
  BiQrScan,
  BiRedo,
  BiShapeCircle,
  BiSolidEnvelope,
  BiUser,
  BiUserCircle,


} from 'react-icons/bi'
import { AccountSwitcher } from './AccountSwitcher'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'
import { FiClipboard } from 'react-icons/fi'
import { ColorModeToggleButton } from '@components/button/ColorModeTogle'

export const ShellWithMenu = ({ children }) => {
  return (
    <Box position="relative">
      <Flex h="full" id="app-container">
        <Box w="64" bg="gray.900" color="white" fontSize="sm">
          <Flex h="full" direction="column" px="4" py="4">
            <AccountSwitcher />
            <Stack spacing="8" flex="1" overflow="auto" pt="8">
              <Stack spacing="1">
                <NavItem href={'/admin/dashboard'} icon={<BiHome />} label="Dashboard" />
              </Stack>
              <NavGroup label="Comuunication">
                <NavItem icon={<BiAddToQueue />} label="Tickets" />
                <NavItem href={'/admin/communication/messages'} icon={<BiCommentAdd />} label="Messages" />
                <NavItem icon={<BiSolidEnvelope />} label="Emails" />
              </NavGroup>
              <NavGroup label="Admin Tools">
                <NavItem icon={<BiUserCircle />} label="Customers" />
                <NavItem icon={<BiPurchaseTagAlt />} label="Plans" />
                <NavItem href={'/admin/api/services'} icon={<BiShapeCircle />} label="API Services" />
                <NavItem href={'/admin/qrcode'} icon={<BiQrScan />} label="QR Code" />
              </NavGroup>
              <NavGroup label="User">
                <NavItem href="/admin/user/profile" icon={<BiUser />} label="Profile" />
                <NavItem icon={<BiNews />} label="Payment" />
                <NavItem icon={<BiEnvelope />} label="Invoices" />
                <NavItem icon={<BiRedo />} label="Transfer" />
              </NavGroup>
              <NavGroup label="Business">
                <NavItem href="/admin/business/account" icon={<FiClipboard />} label="Account" />
              </NavGroup>
            </Stack>
            <Box>
              <Stack spacing="1">
                <NavItem subtle icon={<BiCog />} label="Settings" />
                <NavItem
                  subtle
                  icon={<BiBuoy />}
                  label="Help & Support"
                  endElement={<Circle size="2" bg="blue.400" />}
                />
              </Stack>
            </Box>
          </Flex>
        </Box>
        {/**Body */}
        <ShellBody>
          <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" mb="4">
            <NavItem href={'/admin/dashboard'} icon={<BiHome />} label="Dashboard" width='2xs' />
           
            <ColorModeToggleButton/>
          </Flex>
          {children}
        </ShellBody>

      </Flex>

    </Box>
  )
}



const ShellBody = ({ children }) => {
  return (<Box flex="1" p="6">
    <Box
      w="full"
      minH="100vh"
      rounded="lg"
    >
      {children}
    </Box>
  </Box>)

}