// Desc: Business Reducer

import { FETCH_BUSINESS_ACCOUNTS_FAILURE, FETCH_BUSINESS_ACCOUNTS_REQUEST, FETCH_BUSINESS_ACCOUNTS_SUCCESS, FETCH_BUSINESS_FAILURE, FETCH_BUSINESS_REQUEST, FETCH_BUSINESS_SUCCESS } from "@redux/actions/business-actions/businessTypes";

const initialState = {
    business: [],
    selected: null,
    loading: false,
    error: null
};

const businessReducer = (state = initialState, action) => {
    switch (action.type) {
        //FETCH BUSINESS    
        case FETCH_BUSINESS_ACCOUNTS_REQUEST:
            return {
                ...state,
                business: [],
                error: null,
                loading: true
            };
        case FETCH_BUSINESS_ACCOUNTS_SUCCESS:
            return {
                ...state,
                business: action.payload.business,
                loading: false,
                error: null
            };
        case FETCH_BUSINESS_ACCOUNTS_FAILURE:
            return {
                ...state,
                business: [],
                loading: false,
                error: action.payload.error
            };


        default:
            return state;
    }
};




export default businessReducer;
