import { Box, Heading, Stack } from '@chakra-ui/react'
import { ImageWithOverlay } from './ImageWithOverlay'

export const ShowcaseOnSpanningColummns = () => (

  <Box
    maxW="7xl"
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
   
      <Heading
        as="h3"
        size="lg"
        fontWeight="extrabold"
        maxW="48rem"
        mx="auto"
        p={8}
      >
        We make & boost  Your Brand.
      </Heading>

    <Stack
      height={{
        md: '640px',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      spacing={{
        base: '6',
        md: '10',
      }}
      align="stretch"
    >
      <ImageWithOverlay
        flex="1"
        objectPosition="top center"
        // title="Video Promotion Editing"
        src="https://res.cloudinary.com/druyvgiba/image/upload/v1705255823/Corobiz/video_editor_fantasi_tp2knu.svg"
        alt="Promo Video"
      />
      <Stack
        spacing={{
          base: '6',
          md: '10',
        }}
        maxW={{
          md: '400px',
        }}
      >
        <ImageWithOverlay

         
          src="https://res.cloudinary.com/druyvgiba/image/upload/v1705525780/Corobiz/AdobeStock_636277989_Preview_ouju8h.png"
          alt="Lovely Image"
        />
        <ImageWithOverlay

          title="Logo Design"
          src="https://res.cloudinary.com/druyvgiba/image/upload/v1705189245/Corobiz/AdobeStock_394111545_Preview_a28udo.jpg"
          alt="Lovely Image"
        />
      </Stack>
    </Stack>
  </Box>
)
