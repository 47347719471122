

import { HeroWithStatsBelow } from '@components/heroes/HeroWithStatsBelow'
import { RocketFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
export const MarketingPageBusinessBosterLayout = () => {
    const navigate = useNavigate();
    return (<HeroWithStatsBelow minH='100vh'
        bgImage="url(https://res.cloudinary.com/druyvgiba/image/upload/v1705174347/Corobiz/AdobeStock_620547890_qbl9ox.jpg)"
        title='Advertising & Marketing'
        action={{
            title: "Business Booster",
            icon:<RocketFill />,
            onClick: () => navigate('/marketing')
        }}
        stats={stats}
    />)
}

const stats = [
    {
        title: 'Templates',
        value: '650+'
    },
    {
        title: 'Assets',
        value: '5K+'
    },
    {
        title: 'Features',
        value: '100+'
    }
]