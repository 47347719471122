import { Box, Container, SimpleGrid } from '@chakra-ui/react'
import { FiMail, FiSend, FiUsers } from 'react-icons/fi'
import { Stat } from './Stat'
import { BiAddToQueue } from 'react-icons/bi'
const stats = [
  {
    icon: BiAddToQueue,
    label: 'New Tickets',
    value: '71,887',
    delta: {
      value: '320',
      isUpwardsTrend: true,
    },
  },
  {
    icon: FiUsers,
    label: 'Customers',
    value: '56.87%',
    delta: {
      value: '2.3%',
      isUpwardsTrend: true,
    },
  },
  {
    icon: FiMail,
    label: 'Emails',
    value: '56.87%',
    delta: {
      value: '2.3%',
      isUpwardsTrend: true,
    },
  },
  {
    icon: FiSend,
    label: 'Avg. Click Rate',
    value: '12.87%',
    delta: {
      value: '0.1%',
      isUpwardsTrend: false,
    },
  },
]

export const StatIcon = () => (
  <Box
    as="section"
    py={{
      base: '4',
      md: '8',
    }}
  >
    <Container>
      <SimpleGrid
        columns={{
          base: 1,
          md: 3,
        }}
        gap={{
          base: '5',
          md: '6',
        }}
      >
        {stats.map((stat, id) => (
          <Stat key={id} {...stat} />
        ))}
      </SimpleGrid>
    </Container>
  </Box>
)
