



import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadUser } from '@redux/actions/user-actions/loadUser';

import { Box, Center, Container, Spinner } from '@chakra-ui/react';
import { Logo } from '@components/CoroBizLogo.jsx';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';//importb all styles for toastify
import '@locales/i18n';// import i18n (needs to be bundled ;))

import { Router } from './router/Router.js';//Import all routes

import { NavbarWithCallToAction } from '@components/Navbar/NavbarWithCallToAction.jsx';//Global Navbar
import { FooterWithLogoBelow } from '@components/footer/FooterWithLogoBelow/FooterWithLogoBelow.jsx';//Global Footer


function App() {
  const [appReady, setAppReady] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    let cancelSubscription = () => { }
    if (appReady)
      cancelSubscription = dispatch(loadUser());
    return () => cancelSubscription;
  }, [dispatch, appReady]);

  useEffect(() => {
    setTimeout(() => setAppReady(true), 1000); // Wait for the CSS 
  }, [])

  if (!appReady)
    return (<Box>
      <Container>
        <Center>
          <Logo h='25vh' w='25vw'/>
        </Center>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Spinner color='blue.500'/>
        </Box>
      </Container>
    </Box>)
  return (<>
  
    
    <ToastContainer
      position="top-right"
      newestOnTop
      stacked
      autoClose={15000}
      hideProgressBar={false}
      closeOnClick
      rtl={false}
      pauseOnHover
      theme="dark"
    />
    <Router />

    <FooterWithLogoBelow />
  </>
  );
}

export default App;
