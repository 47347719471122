export const steps = [

  {
    title: 'Business Address',
    description: 'Auth information for management features',
  },
  {
    title: 'Business Info',
    description: 'Free trial (90 days). All features availables.',
  },
  {
    title: 'Login',
    description: 'Auth information for management features',
  },
  {
    title: 'Agreements',
    description: 'Agreements Consent',
  },
 
]
