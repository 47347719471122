import { NAVBAR_LINK_DASHBOARD, NAVBAR_LINK_HOME, NAVBAR_LINK_LOGIN, NAVBAR_LINK_MARKETING, NAVBAR_LINK_PRICING, NAVBAR_LINK_SERVICES, NAVBAR_LINK_SIGNUP, NAVBAR_LINK_SUPPORT } from "../types";

const esComponents= {
    navbar: {
        [NAVBAR_LINK_HOME]: "Inicio",
        [NAVBAR_LINK_PRICING]: "Precios",
        [NAVBAR_LINK_SERVICES]: "Servicios",
        [NAVBAR_LINK_MARKETING]: "Mercadeo",
        [NAVBAR_LINK_SUPPORT]: "Soporte",
        [NAVBAR_LINK_LOGIN]: "Entrar",
        [NAVBAR_LINK_SIGNUP]: "Registrarse",
        [NAVBAR_LINK_DASHBOARD]: "Panel"
    }
}


export default esComponents;

