import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Link,
  Stack,
  useBreakpointValue,

} from '@chakra-ui/react'


import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { registerNewUser } from '@redux/actions/user-actions/registerNewUser'
import { toast } from 'react-toastify'
import { useEffect, useRef, useState } from 'react'
import { SignupFormStpesLayout } from './signupFormStpesLayout'

import { useStep } from '../../hooks/useStep'
import { steps } from '../../components/forms/data'
import { BusinesRegistrationFomr } from '../../components/forms/BusinessForm'
import { BusinessAddressForm } from '../../components/forms/BusinessAddressForm'
import { LoginInfoForm } from '../../components/forms/LoginForm'
import { AgreementForm } from '../../components/forms/AgreementForm'


export const SignUpLayout = () => {
  const mounted = useRef();
  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
  })
  const [info, setInfo] = useState({})
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (shouldBlockNavigation) {
        window.onbeforeunload = () => true
      } else {
        window.onbeforeunload = undefined
      }
    }
  });





  const handleStepSelection = (id) => {
    if (!!id && id < currentStep) {
      setStep(id)
    }

  }

  const onSubmitAddressInfo = (values = {}) => {
    const { address } = values
    setInfo({ ...info, address: { ...address, completed: true } })
    //setAddressPath(values) Add the values to the url
    setShouldBlockNavigation(true)

    setStep(1)

  }
  const onSubmitBusinessInfo = (values) => {

    setInfo({ ...info, business: { ...values, completed: true } })
    ///setBusinessPath(values) Add the values to the url
    setStep(2)

  }

  const onSubmitLoginInfo = (values) => {
    setInfo({ ...info, login: { ...values, completed: true } })
    setStep(3)
  }


  const register = () => {
    try {
      setSubmitting(true);
      dispatch(registerNewUser(info))
        .then(res => {
          const { user, message } = res?.data;
          toast.success(message);
          navigate(`/email_verification/${user?.uid}`)
          setSubmitting(false);
        })
        .catch(err => {
          toast.error(err);
          console.log(err)
          setSubmitting(false);
        });
    } catch (error) {
      console.log(error)
      toast.error(error?.message)
      setSubmitting(false);
    }


  };


  return (<Box maxW={'full'} h={'100%'}>

    <Container w="full" my={{ base: 2, md: '5%' }}>
      <Stack spacing="6" align="center" w="full" >
        <Heading
          size={{
            base: 'xs',
            md: 'sm',
          }}
        >
          Register your Business
        </Heading>
        <Button variant={'unstyled'} as={Link} onClick={() => navigate("/login")}> Already have an account ?</Button>
      </Stack>
      <Center >
        <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 2 : 8} p={5} w={'full'} justifyContent={'center'}>
          <Box>
            <SignupFormStpesLayout steps={steps} setStep={handleStepSelection} currentStep={currentStep} />
          </Box>

          <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" minW={'25vw'} maxW={{ base: 'none', lg: '40vw' }} p={5}>
            {currentStep === 0 && <BusinessAddressForm defaultValues={{ ...info?.address }} onSubmit={onSubmitAddressInfo} />}
            {currentStep === 1 && <BusinesRegistrationFomr defaultValues={{ ...info?.business }} onSubmit={onSubmitBusinessInfo} />}
            {currentStep === 2 && <LoginInfoForm defaultValues={{ ...info?.login }} onSubmit={onSubmitLoginInfo} />}
            {currentStep === 3 && <AgreementForm defaultValues={{ ...info?.login }} onSubmit={register} />}
          </Box>

        </Stack>
      </Center>
    </Container>
  </Box>)
}

