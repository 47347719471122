import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { enGlobal } from "./en/enGlobal";
import { esGlobal } from "./es/esGlobal";
const resources = {
  en: {
    translation: enGlobal
  },
  es: {
    translation: esGlobal
  }
};





i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(I18nextBrowserLanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;