/**
 * This function to Setup config/headers and token
 * it gets the token from our main state and attach it to the header
 */


import { getApp } from 'firebase/app';
import { ReCaptchaEnterpriseProvider, getToken, initializeAppCheck } from 'firebase/app-check';
import { getAuth } from "firebase/auth";

/**
 * 
 * @param OptionalParams  - optional parameters for headers. It can be null or an object with properties:
 *                          contentType (default is application/json)
 *                          accept (default is application/json)
 *                          auth (boolean), if true then add a Bearer Token in the Authorization Header
 * @param  Headers header variables to be included in the request
 * @returns Object with headers and token for axios requests.
 */
export const tokenConfig = async (
    optionalParams = null,
    headers = {}
) => {
    try {
        const app = getApp()
        let appCheckTokenResponse;
        if (!app.name) throw new Error("Firebase App is not initialized");
        try {
            const appCheck = initializeAppCheck(app,
                {
                    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_ENTERPRISE_KEY),
                    isTokenAutoRefreshEnabled: true // Optional but recommended for best security practices as this enables App Check tokens to be automatically refreshed on expiration
                }
            );
            appCheckTokenResponse = await getToken(appCheck);
        } catch (error) {
            console.log(error)
        }


        // Get token from localstorage
        const token = await getAuth()?.currentUser?.getIdToken();

        //Merge optional params with headers passed by the function's caller
        const config = {
            headers: {
                "Content-type": "application/json",
                ...headers
            },
            params: {}
        };

        // If token then add it to headers
        if (token) {
            config.headers["X-auth-token"] = token;
        }

        //If we get the appChekTokenResponse add it to headers
        if (appCheckTokenResponse) {
            config.headers["X-Firebase-AppCheck"] = appCheckTokenResponse.token;
        }

        if (optionalParams) {
            config.params = optionalParams;
        }

        return config;
    } catch (err) {
        console.log(err.message)
        // Handle any errors if the token was not retrieved.
        return false;
    }
};








