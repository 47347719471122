import {
  Box,
  Button,
  Center,
  LightMode,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as Logos from './Brands'
import React from 'react'
import ReactPlayer from 'react-player/youtube'

export const MarketingWithScreenBelow = () => {

  return (
    <Box>
      <Box as="section"  py="7.5rem">
        <Box
          maxW={{
            base: 'xl',
            md: '5xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Box textAlign="center">

            <Text fontSize="xl" mt="4" maxW="xl" mx="auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore
            </Text>
          </Box>

          <Stack
            justify="center"
            direction={{
              base: 'column',
              md: 'row',
            }}
           
          >
            <LightMode>
              <Button size="lg" colorScheme="blue" px="8" fontWeight="bold" fontSize="md">
                Get started free
              </Button>
              <Button size="lg" colorScheme="whiteAlpha" px="8" fontWeight="bold" fontSize="md">
                Request demo
              </Button>
            </LightMode>
          </Stack>


        </Box>
      </Box>
      <Center>
        <ReactPlayer volume={0.2} controls width={'45vw'} height={'50vh'}  url='https://www.youtube.com/watch?v=wr9INQEZlsI' />
      </Center>
      <Box as="section" py="24">
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <Text
            fontWeight="bold"
            fontSize="sm"
            textAlign="center"
            textTransform="uppercase"
            letterSpacing="wide"
            color={mode('gray.600', 'gray.400')}
          >
            Trusted by over 6,000 blues
          </Text>
          <SimpleGrid
            mt="8"
            columns={{
              base: 1,
              md: 2,
              lg: 6,
            }}
            color="gray.500"
            alignItems="center"
            justifyItems="center"
            spacing={{
              base: '12',
              lg: '24',
            }}
            fontSize="2xl"
          >
            <Logos.ChatMonkey />
            <Logos.Wakanda />
            <Logos.Lighthouse />
            <Logos.Plumtic />
            <Logos.WorkScout />
            <Logos.Finnik />
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
