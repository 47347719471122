import { Box, Button, Flex, Heading, Image, Stack, useColorModeValue } from '@chakra-ui/react'
import { BiSolidDashboard, } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Trans } from "react-i18next";
import { DASHBOARD_BUTTON, WELCOME_BUSINESS_HEADER } from '../../locales/types'


export const HomePageMainLayout = ({ props }) => {
  const navigate = useNavigate()
  return (<Box
    as="section" id='home-header-section'
    backgroundRepeat={'no-repeat'}
    backgroundImage={"https://res.cloudinary.com/druyvgiba/image/upload/v1707409766/Corobiz/pages/home/oonjwqhlazcwhy3pvgj2.jpg"}
    bgSize={'cover'}
    position={'relative'} >
    <Flex
      id="image-wrapper"
      position="absolute"
      insetX="0"
      insetY="0"
      w="full"
      h="full"
      overflow="hidden"
      align="center">
    </Flex>
    <Box h={{ base: "130vh", sm: "110vh", xl: "100vh" }} maxHeight={'1700px'} position="relative" {...props}>
      <Box m={0} py="32" position="relative" zIndex={1}>
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '10',
            xl: '3'
          }}
          color="white"
        >
          <Box maxW="xl" p={8} >
            <Heading className='page-main-heading' as="h4" size="lg" fontWeight="extrabold">
              <Trans i18nKey={`page.home.section.main.${WELCOME_BUSINESS_HEADER}`} />
            </Heading>
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              mt="10"
              spacing="4"
            >
              <Button
                bgColor={'green.600'}
                color={'white'}
                px="8"
                rounded="full"
                size="lg"
                fontSize="md"
                fontWeight="bold"
                leftIcon={<BiSolidDashboard />}
                onClick={() => navigate(`/admin/dashboard`)}
              >
                <Trans i18nKey={`page.home.section.main.${DASHBOARD_BUTTON}`} />
              </Button>
             
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box display={useColorModeValue('none', 'flex')} position="relative" w="full" h="full">
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
      <Image loading='lazy' maxH={'100vh'} maxW={'75vw'} pos={'absolute'} bottom={{ base: '-5%', md: '-10%', xl: '-5%' }} right={0}
        src='https://res.cloudinary.com/druyvgiba/image/upload/v1705110590/Corobiz/AdobeStock_597463789_lkeefa.png' alt='CoroBiz Young' />
    </Box>
    
  </Box >
  )
}
