import es_home_page from "./esHomePage";
import es_login_page from "./esLoginPage";




const enPages = {

    home: { ...es_home_page },    // Home page of the application. It contains general information about the platform and access to login/    home: "/",    home: es_home_page,
    login: { ...es_login_page }   //Inherits from Spanish version of Login Page. 






}


export default enPages;