
import { HomePageMainLayout } from "@layouts/homePageLayouts/HomePageMainLayout";
import { HomePageFeaturesLayout } from "@layouts/homePageLayouts/HomePageFeaturesLayout";
import { HomePageTrustCompaniesLayout } from "@layouts/homePageLayouts/HomePageTrustCompanies";
import { HomePageTeamLayout } from "@layouts/homePageLayouts/HomePageTeamLayouty";
import { Helmet } from "react-helmet-async";
import { Suspense, useEffect } from "react";
import SEO from "src/searchEnigineO/SEO";
import { Box } from "@chakra-ui/react";


const HomePage = () => {
   
    return (<Box>
        <SEO
            imageUrl="https://ccloudbiz.com/static/images/corobiz/corologo.png"
            title="CoroBiz Business Platform"
            name={'CoroBiz'}
            description={`All your API needs, whether you need to send emails, book events, reserve vacation packages, or store your online data. Our APIs are designed to be user-friendly,  fast, and reliable, so you can focus on what's important - growing your business. With our APIs, you can easily integrate our services into your existing applications and workflows, and start seeing results right away.`} />
        <HomePageMainLayout mt={'2%'} />
        <HomePageFeaturesLayout />
        <HomePageTrustCompaniesLayout />
        <HomePageTeamLayout />
    </Box>)
}

export default HomePage;