
import { Box } from "@chakra-ui/react";
import { SignUpLayout } from "../layouts/signupFormLayouts/SignUpLayout"
import { Helmet } from 'react-helmet-async';




export const SignupPage = () => {

    return (<Box minH={'90vh'}>
        <Helmet>
            <title>Sign-Up  | CoroBiz Business Platform</title>
            <meta name="description" content="Take advantage of our exclusive offer and sign up for all of the features available on our platform for a free trial period!"></meta>
            <meta property="og:url" content="https://corobiz.com/registration"></meta>
        </Helmet>

        <SignUpLayout />

    </Box>)
}