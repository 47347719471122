


import { BsCalendar2DateFill, BsCreditCard, } from 'react-icons/bs'
import { FaAccessibleIcon, FaDesktop, FaEnvelope, FaPage4, } from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'
import {
    Box,
    Button,
    Container,
    Heading,
    Icon,
    SimpleGrid,
    Square,
    Stack,
    Text,
} from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import { Trans } from 'react-i18next'
import { ACCESSIBLE_DESCRIPTION, ACCESSIBLE_HEADER, ADMIN_DASHBOARD_DESCRIPTION, ADMIN_DASHBOARD_HEADER, BUSINESS_PROMOTION_DESCRIPTION, BUSINESS_PROMOTION_HEADER, FEATURES_SECTION_HEADER, ONLINE_BOOKING_DESCRIPTION, ONLINE_BOOKING_HEADER, PAYMENT_DESCRIPTION, PAYMENT_HEADER, SMS_EMAIL_NOTIFICATIONS_DESCRIPTION, SMS_EMAIL_NOTIFICATIONS_HEADER, WEBSITE_TEMPLATES_DESCRIPTION, WEBSITE_TEMPLATES_HEADER } from '../../locales/types'

const transPath = "page.home.section.features"

const features = [
    {
        name: ONLINE_BOOKING_HEADER,
        transPath: transPath,
        description: ONLINE_BOOKING_DESCRIPTION,
        icon: BsCalendar2DateFill,
    },
    {
        name: BUSINESS_PROMOTION_HEADER,
        description: BUSINESS_PROMOTION_DESCRIPTION,
        transPath: transPath,
        icon: IoRocketSharp,
    },
    {
        name: ADMIN_DASHBOARD_HEADER,
        description: ADMIN_DASHBOARD_DESCRIPTION,
        transPath: transPath,
        icon: FaDesktop,
    },
    {
        name: SMS_EMAIL_NOTIFICATIONS_HEADER,
        description: SMS_EMAIL_NOTIFICATIONS_DESCRIPTION,
        link: "/admin/notifications",
        transPath: transPath,
        //buttonText:BUTTON_VIEW_TEXT,    
        icon: FaEnvelope,
    },
    {
        name: PAYMENT_HEADER,
        description: PAYMENT_DESCRIPTION,
        link: "/payments",
        //buttonText: BUTTON_MANAGE_PAYMENTS_TEXT,
        transPath: transPath,
        icon: BsCreditCard,
    },
    {
        name: WEBSITE_TEMPLATES_HEADER,
        description: WEBSITE_TEMPLATES_DESCRIPTION,
        link: "https://www.creative-tim.com/templates?category=website&amp;page=1&amp;isFree=false&amp;sort=most%/templates",
        transPath: transPath,
        icon: FaPage4,
    },

    {
        name: ACCESSIBLE_HEADER,
        description: ACCESSIBLE_DESCRIPTION,
        link: "/accessibility",
        transPath: transPath,
        //buttonText: BUTTON_EDIT_TEXT,
        icon: FaAccessibleIcon,
    },
]
export const HomePageFeaturesLayout = () => {

    return (<Box as="section"
     bg="bg.surface"
     
     >
        <Container
            py={{
                base: '16',
                md: '24',
            }}
        >
            <Stack
                spacing={{
                    base: '12',
                    md: '16',
                }}
            >
                <Stack
                    spacing={{
                        base: '4',
                        md: '5',
                    }}
                    maxW="3xl"
                >
                    <Stack spacing="3">

                        <Heading
                            size={{
                                base: 'sm',
                                md: 'md',
                            }}
                            color={'accent'}
                        >
                            <Trans i18nKey={`${transPath}.${FEATURES_SECTION_HEADER}`} />
                        </Heading>
                    </Stack>

                </Stack>
                <SimpleGrid
                    columns={{
                        base: 1,
                        md: 2,
                        lg: 3,
                    }}
                    columnGap={8}
                    rowGap={{
                        base: 10,
                        md: 16,
                    }}
                >
                    {features.map((feature) => (
                        <Stack
                            key={feature.name}
                            spacing={{
                                base: '4',
                                md: '5',
                            }}
                        >
                            <Square
                                size={{
                                    base: '10',
                                    md: '12',
                                }}
                                bg="accent"
                                color="fg.inverted"
                                borderRadius="lg"
                            >
                                <Icon
                                    as={feature.icon}
                                    boxSize={{
                                        base: '5',
                                        md: '6',
                                    }}
                                />
                            </Square>
                            <Stack
                                spacing={{
                                    base: '1',
                                    md: '2',
                                }}
                                flex="1"
                            >
                                <Text
                                    fontSize={{
                                        base: 'lg',
                                        md: 'xl',
                                    }}
                                    fontWeight="medium"
                                >
                                    <Trans i18nKey={`${feature?.transPath}.${feature?.name}`} />

                                </Text>
                                <Text color="fg.muted">  <Trans i18nKey={`${feature?.transPath}.${feature?.description}`} /></Text>
                            </Stack>
                            {!!feature?.link&&<Button
                                variant="text"
                                onClick={() => window.open(feature.link, '_blank')}
                                colorScheme="blue"
                                rightIcon={<FiArrowRight />}
                                alignSelf="start">
                                Read more
                            </Button>}
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
    </Box>
    )
}

