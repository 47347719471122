import { Box, Button, Divider, Heading, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Page404() {
  const navigate = useNavigate()
  return (
    <Box w={'full'} h={'40vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
      <VStack>
        <Heading as={'h1'} size={'3xl'}>
          404
        </Heading>
        <Heading as={'h1'} size={'xl'}>
          Page not found
        </Heading>
        <Divider />
        <Button onClick={() => navigate('/')}>Home</Button>
      </VStack>
    </Box>
  );
}

export default Page404;
