import {
    Avatar,
    AvatarGroup,
    Box,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import { BsChatTextFill, BsMicFill, BsPaperclip, BsPinAngleFill } from 'react-icons/bs'
  import { ChatGroupHeader } from '@components/messages/ChatGroupHeader'
  import { ChatMessage } from '@components/messages/ChatMessage'
  import { SearchInput } from '@components/messages/SearchInput'

  
  export const MessageCenterPage = () => {
    return (
      <Flex overflow="hidden">
        <Stack spacing="4" width="320px" borderEndWidth="1px" pt="6">
          <Box px="5">
            <Text fontSize="lg" fontWeight="medium">
              Messages ({messages.length})
            </Text>
          </Box>
  
          <Box px="5">
            <SearchInput />
          </Box>
  
          <Stack mt="2" spacing="4" flex="1" overflowY="auto" px="5" pb="5">
            <Stack mt="2" spacing="4">
              <ChatGroupHeader icon={BsPinAngleFill}>Pinned</ChatGroupHeader>
              <Stack spacing="0" mx="-4">
                {messages.map((message, index) => (
                  <ChatMessage key={index} data={message} />
                ))}
              </Stack>
            </Stack>
  
            <Stack mt="2" spacing="4">
              <ChatGroupHeader icon={BsChatTextFill}>Messages</ChatGroupHeader>
              <Stack spacing="0" mx="-4">
                {messages.map((message, index) => (
                  <ChatMessage key={index} data={message} />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
  
        <Flex direction="column" flex="1">
          <Flex borderBottomWidth="1px" px="4" py="4">
            <HStack spacing="4" flex="1">
              <Avatar name="Design Group" />
              <Stack spacing="0">
                <Text fontWeight="medium">{group.name}</Text>
                <Text fontSize="sm" color="fg.subtle">
                  {group.stats.count} members, {group.stats.online} online
                </Text>
              </Stack>
            </HStack>
  
            <AvatarGroup size="sm">
              {group.members.map((member) => (
                <Avatar name={member.name} src={member.image} />
              ))}
            </AvatarGroup>
          </Flex>
  
          <Box flex="1" overflow="auto" px="5" py="4">
            <Box borderWidth="2px" borderStyle="dashed" height="full" />
          </Box>
  
          <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">
            <InputGroup>
              <InputLeftElement>
                <IconButton
                  size="sm"
                  aria-label="Record voice"
                  icon={<Icon as={BsMicFill} fontSize="md!" />}
                  variant="ghost"
                />
              </InputLeftElement>
              <Input placeholder="Send a message..." fontSize="sm" />
              <InputRightElement>
                <IconButton
                  size="sm"
                  variant="ghost"
                  aria-label="Send message"
                  icon={<Icon as={BsPaperclip} fontSize="md!" />}
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Flex>
      </Flex>
    )
  }
  

  export const group = {
    name: 'Design Group',
    stats: {
      count: 310,
      online: 20,
    },
    members: [
      {
        name: 'Ryan Giggs',
        image:
          'https://images.unsplash.com/photo-1625504615927-c14f4f309b63?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fGhlYWRzaG90fGVufDB8fDB8fHww',
      },
      {
        name: 'Nat Kells',
        image:
          'https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fGhlYWRzaG90fGVufDB8fDB8fHww',
      },
      {
        name: 'Kent Matts',
        image:
          'https://images.unsplash.com/photo-1631885628726-d60689c330db?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTV8fGhlYWRzaG90fGVufDB8fDB8fHww',
      },
    ],
  }
  export const messages = [
    {
      updatedAt: '2 hrs ago',
      name: 'Segun Adebayo',
      image: '',
      message: 'What is the latest with Chakra UI and this new feature?',
    },
    {
      updatedAt: '1 hr ago',
      name: 'John Doe',
      image: '',
      message: 'How can I use the new feature in my project?',
    },
    {
      updatedAt: '30 mins ago',
      name: 'Jane Doe',
      image: '',
      message: 'Is there any documentation for this new feature?',
    },
    {
      updatedAt: '20 mins ago',
      name: 'Alice',
      image: '',
      message: 'What are the benefits of this new feature?',
    },
    {
      updatedAt: '10 mins ago',
      name: 'Bob',
      image: '',
      message: 'Can I contribute to the development of this new feature?',
    },
  ]
  