

//HOME PAGE
export const WELCOME_BUSINESS_HEADER = 'WELCOME_BUSINESS_HEADER';
export const WELCOME_BUSINESS_DESCRIPTION = "WELCOME_BUSINESS_DESCRIPTION";
export const DASHBOARD_BUTTON = "DASHBOARD_BUTTON";


//HOME PAGE SERVICES 

export const FEATURES_SECTION_HEADER = "FEATURES_SECTION_HEADER"
export const ONLINE_BOOKING_HEADER = "ONLINE_BOOKING_HEADER";
export const ONLINE_BOOKING_DESCRIPTION = "ONLINE_BOOKING_DESCRIPTION"
export const BUSINESS_PROMOTION_HEADER = "BUSINESS_PROMOTION_HEADER"
export const BUSINESS_PROMOTION_DESCRIPTION = "BUSINESS_PROMOTION_DESCRIPTION"
export const ADMIN_DASHBOARD_HEADER = "ADMIN_DASHBOARD_HEADER"
export const ADMIN_DASHBOARD_DESCRIPTION = "ADMIN_DASHBOARD_DESCRIPTION"
export const SMS_EMAIL_NOTIFICATIONS_HEADER = " SMS_EMAIL_NOTIFICATIONS_HEADER"
export const SMS_EMAIL_NOTIFICATIONS_DESCRIPTION = "SMS_EMAIL_NOTIFICATIONS_DESCRIPTION"
export const PAYMENT_HEADER = "PAYMENT_HEADER"
export const PAYMENT_DESCRIPTION = "PAYMENT_DESCRIPTION"
export const WEBSITE_TEMPLATES_HEADER = "WEBSITE_TEMPLATES_HEADER"
export const WEBSITE_TEMPLATES_DESCRIPTION = "WEBSITE_TEMPLATES_DESCRIPTION"
export const ACCESSIBLE_HEADER = "ACCESSIBLE_HEADER"
export const ACCESSIBLE_DESCRIPTION = "ACCESSIBLE_DESCRIPTION"


//LOGIN PAGE
export const LOGIN_HELMET_TITLE="LOGIN_HELMET_TITLE"

export const LOGIN_FORM_TITLE = "LOGIN_FORM_TITLE"
export const LOGIN_FORM_NOT_REGISTERED_QUESTION = "LOGIN_FORM_NOT_REGISTERED_QUESTION"
export const LOGIN_FORM_NOT_REGISTERED_OPTION = "LOGIN_FORM_NOT_REGISTERED_OPTION"
export const LOGIN_FORM_ISSUES_QUESTION = "LOGIN_FORM_ISSUES_QUESTION"
export const LOGIN_FORM_ISSUES_OPTION = "LOGIN_FORM_ISSUES_OPTION"
export const LOGIN_FORM_REMEBER_THIS_DEVICE = "LOGIN_FORM_REMEBER_THIS_DEVICE"

export const LOGIN_FORM_LABEL_EMAIL = "LOGIN_FORM_LABEL_EMAIL"
export const LOGIN_FORM_PLACEHOLDER_EMAIL = "LOGIN_FORM_PLACEHOLDER_EMAIL"

export const LOGIN_FORM_LABEL_PASSWORD = "LOGIN_FORM_LABEL_PASSWORD"
export const LOGIN_FORM_PLACEHOLDER_PASSWORD = "LOGIN_FORM_PLACEHOLDER_PASSWORD"
export const LOGIN_FORM_SUBMIT_BUTTON = "LOGIN_FORM_SUBMIT_BUTTON"
export const LOGIN_FORM_GOOGLE_BUTTON = "LOGIN_FORM_GOOGLE_BUTTON"



//NAVBAR
export const NAVBAR_LINK_HOME = "NAVBAR_LINK_HOME";
export const NAVBAR_LINK_PRICING = "NAVBAR_LINK_PRICING";
export const NAVBAR_LINK_SERVICES = "NAVBAR_LINK_SERVICES";
export const NAVBAR_LINK_MARKETING = "NAVBAR_LINK_MARKETING";
export const NAVBAR_LINK_SUPPORT = "NAVBAR_LINK_SUPPORT";
export const NAVBAR_LINK_LOGIN = "NAVBAR_LINK_LOGIN";
export const NAVBAR_LINK_SIGNUP = "NAVBAR_LINK_SIGNUP";
export const NAVBAR_LINK_DASHBOARD = "NAVBAR_LINK_DASHBOARD";



