



import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Stack,
    Text,
    FormControl,
    InputGroup,
    InputLeftElement,
    InputRightAddon,
    FormLabel,
    Input,
    StackDivider,
    Avatar,
    Tooltip,
    Icon,
    Flex,
    HStack,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    IconButton,
    Link

} from '@chakra-ui/react'
import { Rating } from '@components/Rating'
import AddressInput from '@components/address/AddressInput'
import { Dropzone } from '@components/dropzone/Dropzone'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'

import { BsBuildingFillX, BsEnvelope, BsEnvelopeXFill, BsLockFill, BsShieldCheck } from 'react-icons/bs'
import { FiClipboard } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { tokenConfig } from 'src/middlewares/tokenMiddleware'
import copyToClipBoard from 'src/utils/copytoClipBoard'


export const BusinessAccountDetails = ({ business }) => {

    const [isGeneratingToken, setIsGeneratingToken] = useState(false);
    const [isTokenAvailableToClipboard, setIsTokenAvailableToClipboard] = useState(false);
    const [apiToken, setApiToken] = useState('')
    const navigate = useNavigate();
    useEffect(() => {
        setApiToken(business?.apiToken?.key || '')
    }
        , [business])



    const handleRequestServiceAPItoken = async () => {
        //TODO: call API to request
        try {
            // eslint-disable-next-line no-restricted-globals 
            if (!confirm("Are you sure you  want to unsubscribe?")) {
                return;
            } else {
                setIsGeneratingToken(true)
                const token = await tokenConfig({});
                toast.promise(
                    axios.post(`${process.env.REACT_APP_API_URL}/api/business/token`, { business: business._id }, token),
                    {// eslint-disable-next-line no-restricted-globals
                        pending: {
                            render() {
                                setIsTokenAvailableToClipboard(false)
                                return "Generating Token..."
                            },
                            icon: false,
                        },
                        success: {
                            render({ data: res }) {
                                const message = res?.data?.message || 'Token generated successfully';
                                const transCode = res?.data?.transCode;
                                setIsGeneratingToken(false)
                                setIsTokenAvailableToClipboard(true)
                                setApiToken(res?.data?.token)
                                return message;
                            },
                            // other options
                            icon: "🟢",
                        },
                        error: {
                            render({ data: res }) {
                                const error = res?.data?.error || res?.data?.message || 'An error occurred while generating token';
                                setIsGeneratingToken(false)
                                setIsTokenAvailableToClipboard(false)
                                // When the promise reject, data will contains the error
                                return error;
                            }
                        }
                    }
                )


            }

        } catch (error) {

        }
    }




    return (<Container
        maxW="container.lg"
        py={{
            base: '4',
            md: '8',
        }}
    >
        <Stack spacing="5">
            <Stack
                spacing="4"
                direction={{
                    base: 'column',
                    sm: 'row',
                }}
                justify="space-between"
            >
                <VStack justifyContent={'flex-start'} textAlign={'start'} alignItems={'start'} spacing={3}>
                    <HStack>
                        {business?.status?.active ? <BsShieldCheck color='green' /> : <BsBuildingFillX color='red' />}
                        <Text textStyle="lg" fontWeight="medium" textTransform={'capitalize'}>
                            {business?.name}
                        </Text>
                        <Flex alignItems='center'>
                            <Rating defaultValue={5} />
                            <Text ml="2" color="fg.muted">({200})</Text>
                        </Flex>
                    </HStack>

                    <Text color="fg.muted" textStyle="sm">Member Since: {new Date(business?.createdAt).toLocaleDateString()}</Text>
                    <Divider />
                    <Stack spacing={3}>
                        {business?.status?.active ?
                            <Alert status="success">
                                <AlertIcon />
                                Account Active</Alert>
                            : <Alert status="error">
                                <AlertIcon />
                                Account Inactive</Alert>}

                        {!business?.emailVerified &&
                            <Alert status="warning">
                                <AlertIcon />
                                Your email is not yet verified
                            </Alert>}
                        <Alert status="success">
                            <AlertIcon />
                            Data uploaded to the server. Fire on!
                        </Alert>

                        <Alert status="warning">
                            <AlertIcon />
                            Seems your account is about to expire, upgrade now!
                        </Alert>
                    </Stack>
                </VStack>

            </Stack>
            <Divider />
            {/*<Divider my='6' />*/}

            <HStack mt="4" spacing="4">
                <Heading as={'h2'} fontSize="4xl">
                    Business
                </Heading>
            </HStack>


            <Stack spacing="5" divider={<StackDivider />}>
                <Text>ID : {business?._id}</Text>
                <FormControl id="logo">
                    <Stack
                        justifyContent={'flex-start'}
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: '1.5',
                            md: '8',
                        }}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Logo</FormLabel>
                        <Stack
                            spacing={{
                                base: '3',
                                md: '5',
                            }}
                            direction={{
                                base: 'column',
                                sm: 'row',
                            }}
                            width="full"
                            maxW={{
                                md: '2xl',
                            }}
                        >
                            <Avatar size="lg" name="Christoph Winston" src="https://tinyurl.com/yhkm2ek8" />
                            <Dropzone width="full" />
                        </Stack>
                    </Stack>
                </FormControl>
                <FormControl id="name">
                    <Stack
                        justifyContent={'flex-start'}
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: '1.5',
                            md: '8',
                        }}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Name</FormLabel>
                        <Input
                            maxW={{
                                md: '2xl',
                            }}
                            defaultValue={business?.name}
                        />
                    </Stack>
                </FormControl>
                <FormControl id="domain">
                    <Stack
                        justifyContent={'flex-start'}
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: '1.5',
                            md: '8',
                        }}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Domain</FormLabel>
                        <Input
                            isReadOnly={true}
                            maxW={{
                                md: '2xl',
                            }}
                            defaultValue={business?.domain}
                        />
                    </Stack>
                </FormControl>
                <FormControl id="email">
                    <Stack

                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: '1.5',
                            md: '8',
                        }}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Email</FormLabel>
                        {!business?.emailVerified && <IconButton
                            variant="outline"
                            variantColor="teal"
                            aria-label="Send email Verification"
                            icon={<BsEnvelope />}
                        />}
                        <InputGroup position={'relative'}>
                            <Input
                                type="email"
                                maxW={{
                                    md: '2xl',
                                }}
                                defaultValue={business?.email}
                            />
                            <InputRightAddon>
                                <Tooltip hasArrow placement="top" label={business?.emailVerified ? 'This email has been verified' : 'This email is not yet verified.'}>
                                    {business?.emailVerified ? <BsShieldCheck color='green' size={20} /> : <BsEnvelopeXFill color='red' size={20} />}
                                </Tooltip>
                            </ InputRightAddon>

                        </InputGroup>
                    </Stack>
                </FormControl>
                <FormControl id="website">
                    <Stack
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: '1.5',
                            md: '8',
                        }}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Website</FormLabel>
                        <IconButton as={Link} variant="outline" icon={<BiLinkExternal size={20} />} href={business?.website} />
                        <InputGroup>

                            <Input
                                isReadOnly={true}
                                type="url"
                                maxW={{
                                    md: '2xl',
                                }}
                                defaultValue={business?.website}
                            />
                            <InputRightAddon>

                                <BsLockFill color='green' size={20} />

                            </InputRightAddon>
                        </InputGroup>
                    </Stack>
                </FormControl>


                <Box rounded={'md'} shadow={'lg'} p={4} >
                    <Stack spacing={6}>
                        <AddressInput address={business?.address} />
                    </Stack>
                </Box>

                <HStack mt="4" spacing="4">
                    <Heading as={'h2'} fontSize="4xl">
                        API
                    </Heading>
                </HStack>

                <Stack
                    justifyContent={'flex-start'}
                    direction={{
                        base: 'column',
                        md: 'row',
                    }}
                    spacing={{
                        base: '1.5',
                        md: '8',
                    }}
                    justify="space-between"
                >
                    <FormLabel  >Token</FormLabel>
                    <InputGroup cursor={'pointer'} onClick={() => isTokenAvailableToClipboard ? copyToClipBoard(apiToken) : () => { alert('Token is no longer available to copy') }}>
                        <InputLeftElement display={!isTokenAvailableToClipboard || isGeneratingToken ? 'none' : 'flex'} mr={5}>
                            <Tooltip label="Copy to clipboard">
                                <FiClipboard size={'25'} color='brand.500' />
                            </Tooltip>

                        </InputLeftElement>
                        <Input
                            isReadOnly={true}
                            maxW={{
                                md: '2xl',
                            }}
                            value={apiToken}
                        />
                        <InputRightAddon children={<Badge>Keep it Safe</Badge>} />
                    </InputGroup>
                    <Button onClick={handleRequestServiceAPItoken} isLoading={isGeneratingToken} loadingText='Please wait...'>Generate</Button>
                </Stack>

                <Text>
                    Quote: {business?.apiToken?.quote || 'No quote available'}
                </Text>
                <Box>
                    <h4>Domains:</h4>
                    {business?.apiToken?.domains?.map((domain) => (<Text key={domain}>{domain}</Text>))}
                </Box>




                <Flex direction="row-reverse">
                    <Button>Save</Button>
                </Flex>
            </Stack>
        </Stack>
    </Container>)
}
