export const FETCH_BUSINESS_ACCOUNTS_REQUEST = 'FETCH_BUSINESS_ACCOUNTS_REQUEST';
export const FETCH_BUSINESS_ACCOUNTS_SUCCESS = 'FETCH_BUSINESS_ACCOUNTS_SUCCESS';
export const FETCH_BUSINESS_ACCOUNTS_FAILURE = 'FETCH_BUSINESS_ACCOUNTS_FAILURE';

export const FETCH_BUSINESS_DETAILS_REQUEST = 'FETCH_BUSINESS_DETAILS_REQUEST';
export const FETCH_BUSINESS_DETAILS_SUCCESS = 'FETCH_BUSINESS_DETAILS_SUCCESS';
export const FETCH_BUSINESS_DETAILS_FAILURE = 'FETCH_BUSINESS_DETAILS_FAILURE';





export const ADD_BUSINESS_REQUEST = 'ADD_BUSINESS_REQUEST';
export const ADD_BUSINESS_SUCCESS = 'ADD_BUSINESS_SUCCESS';
export const ADD_BUSINESS_FAILURE = 'ADD_BUSINESS_FAILURE';
export const DELETE_BUSINESS_REQUEST = 'DELETE_BUSINESS_REQUEST';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';
export const DELETE_BUSINESS_FAILURE = 'DELETE_BUSINESS_FAILURE';
export const UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';
export const FETCH_BUSINESS_BY_ID_REQUEST = 'FETCH_BUSINESS_BY_ID_REQUEST';
export const FETCH_BUSINESS_BY_ID_SUCCESS = 'FETCH_BUSINESS_BY_ID_SUCCESS';
export const FETCH_BUSINESS_BY_ID_FAILURE = 'FETCH_BUSINESS_BY_ID_FAILURE';
export const FETCH_BUSINESS_BY_USER_REQUEST = 'FETCH_BUSINESS_BY_USER_REQUEST';
export const FETCH_BUSINESS_BY_USER_SUCCESS = 'FETCH_BUSINESS_BY_USER_SUCCESS';
export const FETCH_BUSINESS_BY_USER_FAILURE = 'FETCH_BUSINESS_BY_USER_FAILURE';
export const FETCH_BUSINESS_BY_CATEGORY_REQUEST = 'FETCH_BUSINESS_BY_CATEGORY_REQUEST';
export const FETCH_BUSINESS_BY_CATEGORY_SUCCESS = 'FETCH_BUSINESS_BY_CATEGORY_SUCCESS';
export const FETCH_BUSINESS_BY_CATEGORY_FAILURE = 'FETCH_BUSINESS_BY_CATEGORY_FAILURE';

// Path: src/redux/actions/business-actions/businessActions.js